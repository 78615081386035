import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Alert, Spinner, Image } from 'react-bootstrap';
import { LanguageProvider } from '../core/language-provider';
import { LoginManager } from '../core/login-manager';
import Dashboard from '../config/Dashboard';


export default function Home() {

    const [data, setData] = useState(null);
    const [errors, setErrors] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
        
        async function init() {
            var userInfo = LoginManager.getUserInfo()
            setData(userInfo)
        }

        init()  
    }, []);


   


    if (data !== null) {
        return (
            <Container fluid='md' className='mt-5 mb-5'>
                <h3>Ciao, {data.name} {data.surname}</h3>
                <Dashboard />
            </Container>
        )
    }
    else {
        return (
            <Container fluid="md" className='mt-5 mb-5'>
                {errors.length > 0 &&
                    <Alert variant='danger'>
                        {errors}
                    </Alert>
                }
                <div className='text-center p-5'>
                    <Spinner animation="border" variant="primary" />
                    <p className='text-primary'>{LanguageProvider.get('defaults.loading')}</p>
                </div>
            </Container>
        )
    }
};