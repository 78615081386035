import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { actions } from '../../../config/hooks/actions';

const hooks = actions.DataGridRow || []


export default function DataGridAction({ entity=null, data }) {

    const [rowActions, setRowActions] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        var temp = []
        if(entity !== null && hooks[entity]) {
            for (var i = 0; i < hooks[entity].length; i++) {
                temp.push(hooks[entity][i]);
            }
        }
        setRowActions(temp)
    }, [entity]);



    if (entity !== null) {
        return (
            <>
                {rowActions.length ? (
                    <>
                        <Dropdown.Divider />
                        {rowActions.map((item, i) => {
                            return (
                                <Dropdown.Item className='small' key={i} onClick={() => item.func(data, navigate)}>{item.label} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon={item.icon} /></span></Dropdown.Item>
                            )
                        })}
                    </>
                ) : (
                    <></>
                )}
            </>
        );
    }
    else {
        return (
            <></>
        )
    }

};