import React from 'react';
import FooterHook from '../../../config/hooks/FooterHook';


export default function FooterBlock() {

    return (
        <>
            <FooterHook />
        </>
    );
};