import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';


export default function CustomPage() {

    const { page } = useParams();
    const MyComponent  = lazy(() => import(`../../config/nl_pages/${page}`).catch(() => ({ default: () => <div>Page not found.</div> })));


    return (
        <Container fluid className='mt-5 mb-5'>
            <Suspense fallback={<div>Loading...</div>}>
                <MyComponent />
            </Suspense>
        </Container>
    )
    
};