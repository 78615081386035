export const menu = [
    {
        "type": 'header',
        "href": '#',
        "icon": '',
        "entity": '',
        "label": 'Pagine'
    },
    {
        "type": 'item',
        "href": '/',
        "icon": 'home',
        "entity": '',
        "label": 'Dashboard'
    },
    {
        "type": 'item',
        "href": '/entity/customer',
        "icon": 'suitcase',
        "entity": 'customer',
        "label": 'Clienti'
    },
    {
        "type": 'item',
        "href": '/entity/service',
        "icon": 'laptop-code',
        "entity": 'service',
        "label": 'Servizi'
    },
    {
        "type": 'item',
        "href": '/entity/deal',
        "icon": 'file-contract',
        "entity": 'deal',
        "label": 'Contratti'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_invoice',
        "icon": 'receipt',
        "entity": 'deal_invoice',
        "label": 'Pagamenti'
    },
    {
        "type": 'item',
        "href": '/entity/task',
        "icon": 'rocket',
        "entity": 'task',
        "label": 'Task'
    },
    {
        "type": 'item',
        "href": '/entity/gyv_task',
        "icon": 'calendar',
        "entity": 'gyv_task',
        "label": 'Scadenze'
    },
    {
        "type": 'item',
        "href": '/entity/notes',
        "icon": 'pen',
        "entity": 'notes',
        "label": 'Notes'
    },


    {
        "type": 'header',
        "href": '#',
        "icon": '',
        "entity": '',
        "label": 'Impostazioni'
    },
    {
        "type": 'item',
        "href": '/settings',
        "icon": 'user-gear',
        "entity": '',
        "label": 'Profilo'
    },
    {
        "type": 'item',
        "href": '/entity/user',
        "icon": 'user',
        "entity": 'user',
        "label": 'Utenti'
    },
    {
        "type": 'item',
        "href": '/entity/user_group',
        "icon": 'users',
        "entity": 'user_group',
        "label": 'Gruppi'
    },
    {
        "type": 'item',
        "href": '/page/GoogleCalendar',
        "icon": 'rotate',
        "entity": '',
        "label": 'Calendario Google'
    },
    {
        "type": 'item',
        "href": '/entity/user_entity_saved_filters',
        "icon": 'save',
        "entity": 'user_entity_saved_filters',
        "label": 'Filtri salvati'
    },
    {
        "type": 'item',
        "href": '/entity/user_entity_preferences',
        "icon": 'cogs',
        "entity": 'user_entity_preferences',
        "label": 'Preferenze entità'
    },
    {
        "type": 'item',
        "href": '/logout',
        "icon": 'arrow-right-from-bracket',
        "entity": '',
        "label": 'Logout'
    },
]