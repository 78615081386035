import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, Row, Col, Spinner } from "react-bootstrap";
import { LanguageProvider } from '../../../core/language-provider';
import { DataManager } from '../../../core/data-manager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from "lodash";


export default function ModalEntityExport({ entity, props, entityPreferences, status, close, params, searchObj }) {


    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);


    useEffect(() => {
        if(data !== null) {
            console.log(data)

            const items = data
            const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
            const header = Object.keys(items[0])
            const csv = [
            header.join(','), // header row first
            ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
            ].join('\r\n')

            //console.log(csv)
            
            var link = document.createElement('a');
            link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
            link.setAttribute('download', 'export-'+entity);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
        }
    }, [data]);


    function closeModal() {
        setLoading(false)
        setData(null)
        close()
    }

    async function exportPage() {
        if(loading === false) {
            setLoading(true)
            var page = params.page || 1
            var limit = params.limit || 100000
            console.warn(entity, params, searchObj)
            var sorting = {
                'field': params.sortingField,
                'order': params.sortingOrder
            }
            
                let query = searchObj.query || ''
                let fields = searchObj.fields || []
                let constraints = searchObj.constraints || []
                var response = await DataManager.exportItems(entity, page, limit, sorting, query, fields, constraints)
            
            if(response.success === 1) {
                console.warn(response)
                setData(response.body)
            }
            else {
                console.warn(response)
            }
            setLoading(false)
        }
    }

    async function exportAll() {
        if(loading === false) {
            setLoading(true)
            var page = 1
            var limit = 100000
            var sorting = {
                'field': params.sortingField,
                'order': params.sortingOrder
            }

                let query = searchObj.query || ''
                let fields = searchObj.fields || []
                let constraints = searchObj.constraints || []
                var response = await DataManager.exportItems(entity, page, limit, sorting, query, fields, constraints)
            
            if(response.success === 1) {
                console.warn(response)
                setData(response.body)
            }
            else {
                console.warn(response)
            }
            setLoading(false)
        }
    }



    if(props !== null && status !== false) { 
        return (
            <>
                <Modal show={status} onHide={closeModal} size="md" scrollable={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>{LanguageProvider.get('defaults.export')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className='mb-3 mt-3'>
                            <Row>
                                <Col xs={6} className='text-center'>
                                    <Button onClick={exportPage} variant='outline-primary' className='w-100'>
                                        <FontAwesomeIcon icon="table" size='3x' className='p-3' />
                                        <br/>
                                        <span className='small'>Esporta pagina</span>
                                    </Button>
                                </Col>
                                <Col xs={6} className='text-center'>
                                    <Button onClick={exportAll} variant='outline-primary' className='w-100'>
                                        <FontAwesomeIcon icon="file-csv" size='3x' className='p-3' />
                                        <br/>
                                        <span className='small'>Esporta tutto</span>
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        {loading === true ? (
                            <Container className='p-3 text-center'>
                                <Spinner variant='secondary' />
                            </Container>
                        ) : (
                            <></>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            {LanguageProvider.get('defaults.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
};