import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Button, Breadcrumb } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language-provider';
import { LoginManager } from '../../core/login-manager';



export default function Logout() {

    let navigate = useNavigate();


    async function handleSubmit() {
        var response = await LoginManager.logout();
        navigate('/')
    }


    return (
        <Container fluid='md' className='mt-5 mb-5'>

            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon="home" /></Breadcrumb.Item>
                    <Breadcrumb.Item active>{LanguageProvider.get('header.logout')}</Breadcrumb.Item>
                </Breadcrumb>
            </Container>

            <Container fluid className='mt-5 mb-5'>
                <h3>{LanguageProvider.get('logout.title')}</h3>
                <p className='lead'>{LanguageProvider.get('logout.subtitle')}</p>
                <Button onClick={handleSubmit} variant='danger' size='lg'>{LanguageProvider.get('logout.confirm')}</Button>
            </Container>

        </Container>
    );
};