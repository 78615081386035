import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Alert, Spinner } from 'react-bootstrap';
import { LanguageProvider } from '../../core/language-provider';
import StandardView from '../../components/Entity/StandardView';


export default function Entity() {

    const [errors, setErrors] = useState('');
    const { entity } = useParams();

    const [entityBefore, setEntityBefore] = useState(null);


    useEffect(() => {
        setEntityBefore(entity)
    }, [entity]);



    if (entityBefore === entity) {
        return (
            <Container fluid className='mt-5 mb-5'>
                <h3>{LanguageProvider.get('entity.'+entity)}</h3>
                <StandardView entity={entity} />
            </Container>
        )
    }
    else {
        return (
            <Container fluid='md' className='mt-5 mb-5'>
                {errors.length > 0 &&
                    <Alert variant='danger'>
                        {errors}
                    </Alert>
                }
                <div className='text-center p-5'>
                    <Spinner animation="border" variant="primary" />
                    <p className='text-primary'>{LanguageProvider.get('defaults.loading')}</p>
                </div>
            </Container>
        )
    }
};