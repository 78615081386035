import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Alert, Spinner, Card, Button, Table, Row, Col, ListGroup, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../core/language-provider';
import { DataManager } from '../../core/data-manager';
import { ViewManager } from '../../core/view-manager';
import FieldPreview from '../../components/Entity/FieldPreview';


export default function EntityItem() {

    const [data, setData] = useState(null);
    const excluded = ['deleted', 'id', 'date', 'lastUpdate'];
    const [errors, setErrors] = useState('');
    const { entity } = useParams();
    const props = ViewManager.getEntityProperties(entity);
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState(searchParams.get("id") || null)

    useEffect(() => {
        async function init() {
            var response = await DataManager.getItem(entity, id);
            if (response.success === 1) {
                console.warn(response.body[0])
                setData(response.body[0])
            }
            else {
                setErrors(LanguageProvider.get('errors.get'))
                console.warn(response)
            }
        }
        init()
    }, []);




    if (data !== null) {
        return (
            <>
                <Container fluid className='mt-5 mb-5'>

                    <h3>{LanguageProvider.get('entity.' + entity)}</h3>

                    <Button onClick={() => navigate(-1)} size="sm" variant="outline-secondary" className='mt-3 mb-2'>
                        <FontAwesomeIcon icon="chevron-left" className='me-1' />
                        {LanguageProvider.get('defaults.back')}
                    </Button>


                    <Row className='mt-4'>
                        <Col md={12} lg={3}>
                            <ListGroup className='mb-2'>
                                <ListGroup.Item>
                                    <div className='d-flex align-items-center'>
                                        <FontAwesomeIcon icon="tag" color={'#aaa'} className='me-2' />
                                        <span className='text-muted' style={{ fontSize: 11 }}>Id elemento</span>
                                    </div>
                                    <div className='small mt-2'>
                                        {data.id}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div className='d-flex align-items-center'>
                                        <FontAwesomeIcon icon="calendar" color={'#aaa'} className='me-2' />
                                        <span className='text-muted' style={{ fontSize: 11 }}>Data creazione</span>
                                    </div>
                                    <div className='small mt-2'>
                                        <FieldPreview item={data} field={'date'} props={props} entity={entity} adv={true} />
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div className='d-flex align-items-center'>
                                        <FontAwesomeIcon icon="pen-to-square" color={'#aaa'} className='me-2' />
                                        <span className='text-muted' style={{ fontSize: 11 }}>Ultima modifica</span>
                                    </div>
                                    <div className='small mt-2'>
                                        <FieldPreview item={data} field={'lastUpdate'} props={props} entity={entity} adv={true} />
                                    </div>
                                </ListGroup.Item>
                                {data.author ? (
                                    <ListGroup.Item>
                                        <div className='d-flex align-items-center'>
                                            <FontAwesomeIcon icon="user" color={'#aaa'} className='me-2' />
                                            <span className='text-muted' style={{ fontSize: 11 }}>Autore</span>
                                        </div>
                                        <div className='small mt-2'>
                                            <FieldPreview item={data} field={'author'} props={props} entity={entity} adv={true} />
                                        </div>
                                    </ListGroup.Item>
                                ) : null}
                                {data.idUserGroup ? (
                                    <ListGroup.Item>
                                        <div className='d-flex align-items-center'>
                                            <FontAwesomeIcon icon="folder" color={'#aaa'} className='me-2' />
                                            <span className='text-muted' style={{ fontSize: 11 }}>Gruppo</span>
                                        </div>
                                        <div className='small mt-2'>
                                            <FieldPreview item={data} field={'idUserGroup'} props={props} entity={entity} adv={true} />
                                        </div>
                                    </ListGroup.Item>
                                ) : null}
                            </ListGroup>
                        </Col>
                        <Col md={12} lg={9}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        {Object.keys(data).map((key, i) => {
                                            var className = "mb-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3";
                                            if (props.columns && props.columns[key].options && props.columns[key].options.className) {
                                                className = props.columns[key].options.className
                                            }
                                            var input_type = props.columns && props.columns[key] ? props.columns[key].input_type : null
                                            switch (input_type) {
                                                case 'richText': className = 'mb-4 col-xs-12 col-sm-12 col-md-12';
                                                case 'deepExternalEntity': className = 'mb-4 col-xs-12 col-sm-12 col-md-12';
                                                case 'deepExternalEntity': className = 'mb-4 col-xs-12 col-sm-12 col-md-12';
                                                default: className = className
                                            }

                                            if (!excluded.includes(key)) {
                                                return (
                                                    <Col key={i} data-name={'view-item-field-' + key} className={className}>
                                                        <label className='text-muted' style={{ fontSize:12 }}>
                                                            <span>{LanguageProvider.getLabel(entity, key)}</span>
                                                        </label>
                                                        <div className='mt-1'>
                                                            <Card>
                                                                <Card.Body className='p-2' style={{ minHeight: 40 }}>
                                                                    <FieldPreview item={data} field={key} props={props} entity={entity} adv={true} />
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    </Col>
                                                )
                                            }
                                        })}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                    {errors.length > 0 &&
                        <Alert className='mt-4' variant='danger'>
                            {errors}
                        </Alert>
                    }
                </Container>
            </>
        )
    }
    else {
        return (
            <Container fluid='md' className='mt-5 mb-5'>
                {errors.length > 0 &&
                    <Alert variant='danger'>
                        {errors}
                    </Alert>
                }
                <div className='text-center p-5'>
                    <Spinner animation="border" variant="primary" />
                    <p className='text-primary'>{LanguageProvider.get('defaults.loading')}</p>
                </div>
            </Container>
        )
    }
};