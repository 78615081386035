import React, { useState } from 'react';
import { Button, Form, InputGroup, Modal, Image, Badge, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { LanguageProvider } from '../../../core/language-provider';
import { ResourceManager } from '../../../core/resource-manager';


export default function CropperField({ field, label, options, validation, value, update, entity }) {

    const [showModal, setShowModal] = useState(false)
    const [uploading, setUploading] = useState(false);
    const [image, setImage] = useState('');
    const [cropper, setCropper] = useState('');
    const [errors, setErrors] = useState('');


    async function uploadFile(base64) {
        if(base64 !== '') {
            setUploading(true)        
            let filename = Date.now() + '.jpg'
            let response = await ResourceManager.uploadBase64(base64, filename, entity, field)
            setUploading(false)
            if(response.success === 1) {
                update(field, response.body)
                cropper.clear()
                setImage('')
                closeModal()
            }
            else {
                cropper.clear()
                setImage('')
                setErrors(response.message)
            }
        }
        else {
            console.log('select a file...')
        }
    }

    const onChange = (e) => {
        e.preventDefault();
        console.warn(options)
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };


    function save() {
        if (typeof cropper !== "undefined") {
            let base64 = cropper.getCroppedCanvas({
                minWidth: 100,
                minHeight: 100,
                width: typeof options !== 'undefined' ? options.width : 1200,
                height: typeof options !== 'undefined' ? options.height : 800
            }).toDataURL()
            uploadFile(base64)
        }
    }

    function closeModal() {
        cropper.clear()
        setImage('')
        setShowModal(false)
        setErrors('')
    }

    function openModal() {
        setShowModal(true)
    }

    function removeFile(value, entity) {
        console.warn("removing file: ", value, entity)
        update(field, null)
    }


    return (
        <>
            <Form.Group>
                <Form.Label className='small'>{label}</Form.Label>
                <br />
                <InputGroup>
                    {typeof value !== 'undefined' && value && validation.type === 'image' ? (
                        <InputGroup.Text className="w-75 bg-white" style={{flexFlow: 'wrap'}}> 
                            <span className='me-1 mb-1 mt-1'>
                                <Image
                                    src={ResourceManager.getFileUrl(value, entity)}
                                    alt="image"
                                    width={'auto'}
                                    height={'50px'}
                                />
                                <Badge onClick={() => removeFile(value, entity)} pill bg="danger" role="button" className='align-top' style={{marginTop:-5, marginLeft:-8}}>
                                    <FontAwesomeIcon icon="times" />
                                </Badge>
                            </span>
                        </InputGroup.Text>
                    ) : (
                        <Form.Control className="w-75" disabled />
                    )}
                    <Button className="w-25" variant="secondary" onClick={() => openModal()}>
                        <FontAwesomeIcon icon="image" />
                    </Button>
                </InputGroup>
            </Form.Group>


            <Modal show={showModal} onHide={closeModal} size="lg" scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{LanguageProvider.get('defaults.image')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errors.length > 0 && (
                        <Alert variant='danger' className='mb-4'>
                            {errors}
                        </Alert>
                    )}
                    <input type="file" onChange={onChange} />
                    <br />
                    <br />
                    <Cropper
                        style={{ height: 400, width: '100%' }}
                        zoomTo={0.5}
                        aspectRatio={typeof options !== 'undefined' ? options.width/options.height : 1}
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={250}
                        minCropBoxWidth={400}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={save}>
                        {LanguageProvider.get('defaults.save')}
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        {LanguageProvider.get('defaults.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};