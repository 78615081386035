import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Spinner, Button } from 'react-bootstrap';


export default function NotFound() {

    const navigate = useNavigate()

    useEffect(() => {
        navigate('/')
    }, []);


    return (
        <Container fluid='md' className='m-5 p-5 text-center'>
            <Spinner />
            <br/><br/>
            <h1>Pagina non trovata</h1>
            <Link to='/'>
                <Button variant='dark' size='lg'>
                    Torna alla homepage
                </Button>
            </Link>
        </Container>
    );
};