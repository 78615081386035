import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown, Image, Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../../core/language-provider';
import { LoginManager } from '../../../core/login-manager';
import Sidebar from '../Sidebar';
import avatars from '../../../core/avatar';
import HeaderHook from '../../../config/hooks/HeaderHook';


export default function HeaderBlock() {

    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    let location = useLocation();

    useEffect(() => {
        console.log('location: ', location)
        var element = document.getElementById("app-container");
        element.setAttribute("data-page", location.pathname);
    }, [location]);


    useLayoutEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [location.pathname]);


    useEffect(() => {
        async function init() {
            var userInfo = LoginManager.getUserInfo()
            setUserInfo(userInfo)
        }
        init()
    }, []);


    function toggleSidebar() {
        var element = document.getElementById("column-container");
        if (sidebarOpen === true) {
            element.classList.remove("sidebar-open");
        }
        else {
            element.classList.add("sidebar-open");
        }
        setSidebarOpen(!sidebarOpen)
    }



    return (
        <>
            <Navbar bg="light" sticky="top" id="header-navbar">
                <Container fluid>
                    <Nav className="me-auto" id="header-menu" data-sidebar={sidebarOpen}>
                        <Nav.Item role='button' onClick={toggleSidebar}>
                            <Button variant={'light'}>
                                <FontAwesomeIcon size={'lg'} icon="bars" />
                            </Button>
                        </Nav.Item>
                    </Nav>
                    <Container>
                        <HeaderHook />
                    </Container>
                    <Dropdown>
                        <Dropdown.Toggle id="header-user-avatar" variant={'light'}>
                            {userInfo && userInfo.avatar !== null ? (
                                <Image className="avatar-image"
                                    src={avatars[userInfo.avatar]}
                                    alt="user pic"
                                    width={'40px'}
                                    height={'40px'}
                                    roundedCircle
                                />
                            ) : (
                                <Image className="avatar-image"
                                    src={avatars[2]}
                                    alt="user pic"
                                    width={'40px'}
                                    height={'40px'}
                                    roundedCircle
                                />
                            )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={'end'} style={{width: 200}}>
                            <Dropdown.ItemText>
                                <p className='text-muted small m-0'>
                                    <span style={{ wordBreak: 'break-all' }}>{userInfo && userInfo.username}</span><br/>
                                    <span style={{ wordBreak: 'break-all', fontSize: '9px' }}>{userInfo && userInfo.email}</span>
                                </p>
                            </Dropdown.ItemText>
                            <NavDropdown.Divider />
                            <NavDropdown.Item className='small' to="/settings" as={Link}>{LanguageProvider.get('header.settings')}</NavDropdown.Item>
                            <NavDropdown.Item className='small' to="/logout" as={Link}>
                                {LanguageProvider.get('header.logout', 'Logout')}
                            </NavDropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Container>
            </Navbar >
            <Sidebar open={sidebarOpen} userInfo={userInfo} />
        </>
    );
};