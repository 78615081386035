import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, Table } from "react-bootstrap";
import { LanguageProvider } from '../../../core/language-provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ViewManager } from '../../../core/view-manager';
import { DataManager } from '../../../core/data-manager';
import { toast } from 'react-toastify';


export default function ModalEntityConfig({ entity, props, entityPreferences, status, close }) {

    const [newFields, setNewFields] = useState(null)

    useEffect(() => {
        if(entity !== null) {
            setNewFields(entityPreferences)
        }
    }, [entity]);


    function closeModal() {
        close(newFields)
    }

    function save(item, value) {
        if(value === 0) {
            let newArr = newFields.columns;
            let index = newArr.indexOf(item);
            newArr.splice(index, 1);

            setNewFields({'columns': newArr})
        }
        else {
            if(newFields.columns.includes(item)) {

            }
            else {
                let newArr = newFields.columns;
                newArr.push(item)
                setNewFields({'columns': newArr})
            }
        }
    }

    function moveUp(item, i) {
        if(i !== 0) {
            let newArr = newFields.columns;
            let tempItem = newArr[i-1]
            newArr[i-1] = item
            newArr[i] = tempItem
            setNewFields({'columns': newArr})
        }
        
    }

    function moveDown(item, i) {
        let newArr = newFields.columns;
        if(i < (newArr.length - 1)) {
            let newArr = newFields.columns;
            let tempItem = newArr[i+1]
            newArr[i+1] = item
            newArr[i] = tempItem
            setNewFields({'columns': newArr})
        }
    }

    async function savePreferences() {
        var p = newFields;

        var c = [
            {
                fieldName: 'entity',
                type: 'value',
                value: entity
            }
        ]
        var response = await DataManager.searchItems('user_entity_preferences', 1, 1, { 'field': 'id', 'order': 'ASC' }, '', [], c)
        if(response.success === 1 && response.body && response.body.length) {
            //update
            var id = response.body[0].id
            var body = {
                content: JSON.stringify(p),
                entity: entity
            }
            var response2 = await DataManager.updateItem('user_entity_preferences', body, id)
            if (response2.success === 1) {
                toast.success('Preferenze aggiornate con successo.', { theme: 'colored' });
            }
            else {
                toast.error('Non è stato possibile salvare le preferenze.', { theme: 'colored' });
            }
        }
        else {
            //insert new
            var body = {
                content: JSON.stringify(p),
                entity: entity
            }
            var response2 = await DataManager.insertItem('user_entity_preferences', body)
            if (response2.success === 1) {
                toast.success('Preferenze salvate con successo.', { theme: 'colored' });
            }
            else {
                toast.error('Non è stato possibile salvare le preferenze.', { theme: 'colored' });
            }
        }
    }


    if(props !== null && status !== false && newFields !== null) { 
        return (
            <>
                <Modal show={status} onHide={closeModal} size="lg" scrollable={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>{LanguageProvider.get('defaults.options')} - {LanguageProvider.get('entity.'+entity)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={6}>
                                <Table striped bordered className='small'>
                                    <tbody>
                                        {newFields.columns.map((item, i) => {
                                            var label = LanguageProvider.getLabel(entity, item)
                                            return (
                                                <tr key={i}>
                                                    <td>{label}</td>
                                                    <td className='text-center' width={'60'}>
                                                        {i !== 0 ? (
                                                            <span role="button" onClick={() => moveUp(item, i)}>
                                                                <FontAwesomeIcon icon="chevron-up" />
                                                            </span>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </td>
                                                    <td className='text-center' width={'60'}>
                                                        {i < (newFields.columns.length - 1) ? (
                                                            <span role="button" onClick={() => moveDown(item, i)}>
                                                                <FontAwesomeIcon icon="chevron-down" />
                                                            </span>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col sm={6}>
                                {ViewManager.getColumns(entity).map((item, i) => { 
                                    var label = LanguageProvider.getLabel(entity, item)
                                    return(
                                        <div key={i} className='small'>
                                            <Form.Group className="mb-3">
                                                <Form.Check 
                                                    type="checkbox" 
                                                    onChange={(event) => save(item, +event.target.checked)} 
                                                    checked={newFields.columns.includes(item) ? 1 : 0}
                                                    label={label} 
                                                />
                                            </Form.Group>
                                        </div>
                                    )
                                })}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => savePreferences()}>
                            Salva preferenze
                        </Button>
                        <Button variant="secondary" onClick={closeModal}>
                            {LanguageProvider.get('defaults.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
};