import logo from './logo.svg';

export const settings = {
    "title": "Innoways CRM",
    "apiUrl": "https://api.crm.innoways.it/api",
    "ckdPath": "https://api.crm.innoways.it/",
    "tinyKey": "u2y9r6cw0e1erg33drzg4fs0swwi1zrs85bcin3ynrsxlwz2",
    "config": {
        "limit": 100,
        "sortingField": 'id',
        "sortingOrder": 'desc'
    },
    "configEntity": {
        "gyv_task": {
            "limit": 100,
            "sortingField": 'deadline',
            "sortingOrder": 'asc'
        }
    },
    "theme": {
        "siderbarIconColor": 'rgba(233,236,239,.5)'
    },
    "logo": logo
}