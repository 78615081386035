import React, { useState } from 'react';
import { LanguageProvider } from '../../../core/language-provider';
import { Modal, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataManager } from '../../../core/data-manager';
import { AuthorizationManager } from '../../../core/authorization-manager';
import DataGridSelectionHook from '../../../config/hooks/DataGridSelectionHook';


export default function DataGridSelection({ props, data, setData, entity, selectedRows, setSelectedRows, refreshData }) {


    const [showDelete, setShowDelete] = useState(false);

    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);


    async function deleteAll() {
        var temp = [...data]
        if(selectedRows.length) {
            for(var i=0; i<selectedRows.length; i++) {
                if(props.reference_entity) {
                    var response = await DataManager.deleteItem(props.reference_entity, selectedRows[i])
                }
                else {
                    var response = await DataManager.deleteItem(entity, selectedRows[i])
                }
                if(response.success === 1) {
                    for(var j=0; j<temp.length; j++) {
                        var item = temp[j]
                        if(item.id === selectedRows[i]) {
                            temp.splice(j, 1);
                        }
                    }
                }
            }
            setData(temp)
            setSelectedRows([])
            handleCloseDelete()
        }
    }

    function selectAll() {
        var newSelection = [...selectedRows]
        var temp = [...data]
        for(var j=0; j<temp.length; j++) {
            var item = temp[j]
            if(!newSelection.includes(item.id)) {
                newSelection.push(item.id)
            }
        }
        setSelectedRows(newSelection)
    }

    function deselectAll() {
        setSelectedRows([])
    }


    if (selectedRows.length) {

        return (
            <>
                <Badge bg="secondary" className="mb-2">{LanguageProvider.get('defaults.selected_rows')} {selectedRows.length}</Badge>
                <Badge role="button" onClick={() => selectAll()} bg={selectedRows.length === data.length ? "success" : "dark"} className="mb-2 ms-2"><FontAwesomeIcon icon="square-check" /></Badge>
                <Badge role="button" onClick={() => deselectAll()} bg="dark" className="mb-2 ms-2"><FontAwesomeIcon icon="square" /></Badge>
                <br />
                {AuthorizationManager.checkEntityActionAuth(entity, 'delete') !== false ? (
                    <Button size="sm" className="me-2" variant={'outline-danger'} onClick={() => handleShowDelete()}>{LanguageProvider.get('defaults.delete_selection')} <span style={{ width: 30, textAlign: 'center' }} className='float-end'><FontAwesomeIcon icon="trash" /></span></Button>
                ) : (
                    <></>
                )}

                <Modal show={showDelete} onHide={handleCloseDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>{LanguageProvider.get('defaults.delete_selection')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{LanguageProvider.get('defaults.delete_selection_confirm')}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                            {LanguageProvider.get('defaults.close')}
                        </Button>
                        <Button variant="danger" onClick={() => deleteAll()}>
                            {LanguageProvider.get('defaults.confirm')}
                        </Button>
                    </Modal.Footer>
                </Modal>


                <DataGridSelectionHook data={data} setData={setData} entity={entity} selectedRows={selectedRows} setSelectedRows={setSelectedRows} refreshData={refreshData} />
            </>
        );
    }
};