import React, { useEffect, useState } from 'react';
import { Container, Alert, Spinner, Card } from 'react-bootstrap';
import { DataManager } from '../core/data-manager';
import { LoginManager } from '../core/login-manager';
import { LanguageProvider } from '../core/language-provider';


export default function Dashboard() {

    const [errors, setErrors] = useState('');
    const [data, setData] = useState(null);

    useEffect(() => {
        async function init() {
            var userInfo = await LoginManager.getUserInfo()
            
        }
        init()

    }, []);



    return (
        <>
            
        </>
    )
};