import axios from 'axios';
import { ApiManager } from '../../../../core/api-manager';

async function calendarList(token) {
    return axios.get(`https://content.googleapis.com/calendar/v3/users/me/calendarList`, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
        }
    }).then((res) => {
        var result = res.data
        return result
    }).catch(async (error) => {
        if(error.response.status === 401) { //verificare che status da il token expired
            var t = localStorage.getItem("GoogleRefeshToken")
            let r = await refreshToken(t)
            if(r.success === 1) {
                if(r.response.access_token) {
                    localStorage.setItem("GoogleAccessToken", r.response.access_token)
                    return calendarList(r.response.access_token)
                }
            }
            else {
                return error
            }
        }
    });
}

async function createCalendar(token, name) {
    return axios.post(`https://content.googleapis.com/calendar/v3/calendars?alt=json`, {
        summary: name,
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
        }
    }).then((res) => {
        return res
    }).catch((error) => {
        return error
    });
}

async function createEvent(token, event, idCal) {
    var res = await axios.post(
        `https://www.googleapis.com/calendar/v3/calendars/${idCal}/events`, JSON.stringify(event), {
            headers: {
                authorization: `Bearer ${token}`, 
                "Content-Type": "application/json"
            }
        }
    );
    return res
}

async function updateEvent(token, event, idCal, idEvent) {
    var res = await axios.put(
        `https://www.googleapis.com/calendar/v3/calendars/${idCal}/events/${idEvent}`, JSON.stringify(event), {
            headers: {
                authorization: `Bearer ${token}`, 
                "Content-Type": "application/json"
            }
        }
    );
    return res
}

async function deleteEvent(token, idCal, idEvent) {
    var res = await axios.delete(
        `https://www.googleapis.com/calendar/v3/calendars/${idCal}/events/${idEvent}`, {
            headers: {
                authorization: `Bearer ${token}`, 
                "Content-Type": "application/json"
            }
        }
    );
    return res
}

async function getTokens(authCode) {
    let body = {
        authCode: authCode
    }
    var res = await ApiManager.sendAuthenticatedRequest('/custom/get_google_access_token', body)
    return res
}

async function refreshToken(token) {
    let body = {
        refreshToken: token
    }
    var res = await ApiManager.sendAuthenticatedRequest('/custom/refresh_google_access_token', body)
    return res
}


export const Gcal = {
    calendarList: calendarList,
    createCalendar: createCalendar,
    createEvent: createEvent,
    updateEvent: updateEvent,
    deleteEvent: deleteEvent,
    getTokens: getTokens,
    refreshToken: refreshToken
};