import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { menu } from '../../../config/menu';
import { settings } from '../../../config/settings';
import { AuthorizationManager } from '../../../core/authorization-manager';


export default function Sidebar({ open, userInfo }) {

    const [folderStatus, setFolderStatus] = useState([]);
    const [menuAuth, setMenuAuth] = useState(null);


    useEffect(() => {
        if (userInfo) {
            console.log('sidebar userinfo detected change', userInfo)
            var userLevel = userInfo.level
            var menu2 = []
            for (var i = 0; i < menu.length; i++) {
                var element = menu[i];
                if(element.hideForLevel && element.hideForLevel.includes(userLevel)) {
                    //elemento nascosto
                }
                else {
                    switch (element.type) {
                        case 'item':
                            if (element.entity && element.entity !== '') {
                                var authorizedItem = AuthorizationManager.checkEntityAuth(element.entity)
                                if (authorizedItem)
                                    menu2.push(element);
                            }
                            else {
                                menu2.push(element);
                            }
                            break;
                        case 'folder':
                            let subMenu = [];
                            for (var j = 0; j < element.items.length; j++) {
                                var subElement = element.items[j]
                                if (subElement.entity && subElement.entity !== '') {
                                    var authorizedItem = AuthorizationManager.checkEntityAuth(subElement.entity)
                                    if (authorizedItem)
                                        subMenu.push(subElement);
                                }
                                else {
                                    subMenu.push(subElement);
                                }
                            }
                            if (subMenu.length > 0) {
                                let newElement = element;
                                newElement.items = subMenu;
                                menu2.push(newElement);
                            }
                            break;
                        default:
                            menu2.push(element)
                    }
                }
            }
            setMenuAuth(menu2)
        }
    }, [userInfo]);

    function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
            return ele != value;
        });
    }
    function arrayAdd(arr, value) {
        arr.push(value);
        return arr.filter(function (ele) {
            return ele;
        });
    }
    function folderToggle(i) {
        let newArray = folderStatus;
        if (newArray.indexOf(i) >= 0) {
            let newFolder = arrayRemove(folderStatus, i);
            setFolderStatus(newFolder)
        }
        else {
            let newFolder = arrayAdd(folderStatus, i);
            setFolderStatus(newFolder)
        }
    }

    function isActivePage(item) {
        var url = window.location.pathname
        var itemUrl = item.href
        return itemUrl === url
    }


    if (userInfo !== null && menuAuth !== null) {
        return (
            <div id='sidebar' data-sidebar={open}>
                <div className='sidebar-logo text-center'>
                    <Link to='/'>
                        <Image
                            src={settings.logo}
                            width="100"
                            height="auto"
                            className="d-inline-block"
                        />
                    </Link>
                </div>
                <div className='sidebar-menu'>
                    {menuAuth.map((item, i) => {
                        i++
                        switch (item.type) {
                            case 'link':
                                return (
                                    <a key={i} href={item.href} className='sidebar-item'>
                                        <FontAwesomeIcon size={'lg'} className='me-2' icon={item.icon} color={settings.theme.siderbarIconColor} /> {item.label}
                                    </a>
                                )
                            case 'item':
                                return (
                                    <Link key={i} to={item.href} className={isActivePage(item) ? 'sidebar-item active' : 'sidebar-item'}>
                                        <FontAwesomeIcon size={'lg'} className='me-2' icon={item.icon} color={settings.theme.siderbarIconColor} /> {item.label}
                                    </Link>
                                )
                            case 'folder':
                                if (item.items.length > 0) { //if folder has almost 1 sub-item
                                    return (
                                        <div key={i} className={folderStatus.includes(i) ? 'sidebar-folder active' : 'sidebar-folder'}>
                                            <span onClick={() => folderToggle(i)} className='sidebar-foldername'>
                                                <FontAwesomeIcon size={'lg'} className='me-2' icon={item.icon} color={settings.theme.siderbarIconColor} /> {item.label}
                                                <span className='chevron'><FontAwesomeIcon icon={folderStatus.includes(i) ? 'chevron-up' : 'chevron-down'} color={'#999'} size={'sm'} /></span>
                                            </span>
                                            <div className='sidebar-subitems'>
                                                {item.items.map((subItem, j) => {
                                                    return (
                                                        <Link to={subItem.href ? subItem.href : ""} key={j} className={isActivePage(subItem) ? 'sidebar-subitem active' : 'sidebar-subitem'}>
                                                            {subItem.label}
                                                        </Link>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                }
                            case 'divider':
                                return (
                                    <div key={i}>
                                        <hr />
                                    </div>
                                )
                            case 'header':
                                return (
                                    <div key={i} className='sidebar-header'>
                                        <span>{item.label}</span>
                                    </div>
                                )
                            default:
                                return (
                                    <div key={i}></div>
                                )
                        }
                    })}
                </div>
            </div>
        );
    }
};
