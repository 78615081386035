import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, Alert, Table } from "react-bootstrap";
import { LanguageProvider } from '../../../core/language-provider';
import { DataManager } from '../../../core/data-manager';
import { ViewManager } from '../../../core/view-manager';
import { Utils } from '../../../core/utils';


export default function ModalEntityImport({ entity, props, entityPreferences, status, close }) {

    const [cols, setCols] = useState(null)
    const [data, setData] = useState([])
    const [warnings, setWarnings] = useState('')
    const [success, setSuccess] = useState('')
    const [errors, setErrors] = useState('')
    const types = ['boolean', 'date', 'string', 'richText', 'currency', 'datetime', 'text']

    useEffect(() => {
        if(entity !== null) {
            let r = []
            let c = Utils.cloneObject(ViewManager.getVisibleColumns(entity))
            for (let i = 0; i < c.length; i++) {
                let a = c[i]
                if(types.includes(props.columns[a].input_type)) {
                    r.push(c[i])
                }
            }
            setCols(r)
        }
    }, [entity]);


    function closeModal() {
        close()
    }

    async function confirmImport() {
        var c = checkData()
        if(c === true) {
            for (let i = 0; i < data.length; i++) {
                var n = {}
                for (let j = 0; j < data[i].length; j++) {
                    n[cols[j]] = data[i][j]
                }
                
                console.warn(n)
                let l = i+1
                var r = await save(n, l)
                if(r === false) {
                    break;
                }
            }
        }
    }

    async function save(el, line) {
        var res = false
        var response = await DataManager.insertItem(entity, el);
        if(response.result === 'success') {
            var s = success
            s = s + 'line ' + line + ': inserted<br/>'
            setSuccess(s)
            res = true
        }
        else {
            if(response.success === 0) {
                setErrors('line ' + line + ': ' + response.message)
            }
            else {
                setErrors('line ' + line + ': ' + response.message)
            }
            res = false
        }
        return res
    }

    function checkImport() {
        var c = checkData()
        if(c === true) {
            setSuccess('dataset is valid')
        }
        else {
            setSuccess('')
        }
    }

    function checkData() {
        var w = ''
        var passed = true
        if(data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                console.log(data[i]);
                if(data[i].length != cols.length) {
                    let line = i+1
                    w = w + 'line ' + line + ': check number of fields<br/>'
                    passed = false
                }
            }
        }
        else {
            w = 'dataset is empty'
            passed = false
        }

        if(passed === false) {
            setWarnings(w)
        }
        else {
            setWarnings('')
        }
        return passed
    }

    function reset() {
        setWarnings('')
        setSuccess('')
        setErrors('')
        setData([])
    }


    function handlePaste(e) {
        if (e.target.tagName && e.target.tagName.match(/(input|textarea)/i)) {
            // Do not handle past when an input element is currently focused
            return;
        }
    
        // Get clipboard data as text
        const data = e.clipboardData.getData("text");
    
        // Simplified parsing of the TSV data with hard-coded columns
        const rows = data.split("\n");
        const result = rows.map((row) => {
            const cells = row.split("\t");
            return cells
        });
        console.log(result)
        setData(result)
    };



    if(props !== null && status !== false && cols !== null) { 
        return (
            <>
                <Modal show={status} onHide={closeModal} size="xl" scrollable={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>{LanguageProvider.get('defaults.import')} - {entity}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container onPaste={handlePaste} className='mb-4 text-center pt-5 pb-5 ps-2 pe-2 border border-success-subtle bg-light text-secondary rounded-2'>
                            {LanguageProvider.get('defaults.paste_here')} Ctrl+V or ⌘+V
                        </Container>

                        <Container>
                            {warnings.length > 0 &&
                                <Alert variant='warning' className='small'>
                                    <div dangerouslySetInnerHTML={{__html: warnings}}></div>
                                </Alert>
                            }

                            {success.length > 0 &&
                                <Alert variant='success' className='small'>
                                    {success}
                                </Alert>
                            }

                            {errors.length > 0 &&
                                <Alert variant='danger' className='small'>
                                    {errors}
                                </Alert>
                            }
                        </Container>

                        <p className='text-secondary'>{LanguageProvider.get('defaults.columns')}</p>
                        <Table responsive striped bordered style={{width: '100%'}} className='small'>
                            <thead>
                                <tr>
                                    <th style={{width: 50}}></th>
                                    {cols.map((item, i) => {
                                        var label = LanguageProvider.getLabel(entity, item)
                                        return (
                                            <th key={i}>{label}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row, j) => {
                                    return (
                                        <tr key={j}>
                                            <td><i>{j+1}</i></td>
                                            {row.map((el, k) => {
                                                return (
                                                    <td key={k}>
                                                        {el}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={reset}>
                            {LanguageProvider.get('defaults.reset')}
                        </Button>
                        <Button variant="primary" onClick={checkImport}>
                            {LanguageProvider.get('defaults.check')}
                        </Button>
                        <Button variant="success" onClick={confirmImport}>
                            {LanguageProvider.get('defaults.import')}
                        </Button>
                        <Button variant="secondary" onClick={closeModal}>
                            {LanguageProvider.get('defaults.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
};