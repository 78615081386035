import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import logoYec from '../../assets/yec.svg';



export default function FooterHook() {

    useEffect(() => {
        
    }, []);



    return (
        <>
            <Container id="footer" fluid className='bg-light p-3'>
                <Container fluid className='text-end'>
                    <Image src={logoYec} width='80' />
                    <p className='text-secondary small'>
                        <span className='small'>© Powered by Gruppo Yec Srl</span><br/>
                        <span className='small'><Link to='/license'>Licenza</Link></span>
                    </p>
                </Container>
            </Container>
        </>
    )
};