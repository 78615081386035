import React, { useState, useEffect } from 'react';
import { Button, Form, InputGroup, Modal, Image, Badge, Alert } from "react-bootstrap";
import { LanguageProvider } from '../../../core/language-provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResourceManager } from '../../../core/resource-manager';


export default function File({ field, label, options, validation, value, update, entity }) {

    const [showModal, setShowModal] = useState(false)
    const [uploading, setUploading] = useState(false);
    const [base64, setBase64] = useState('');
    const [name, setName] = useState('');
    const [errors, setErrors] = useState('');


    const onChange = async (e) => {
        e.preventDefault();
        var selected = e.target.files[0]
        setName(selected.name)
        let select_to_base64 = await blobToBase64(selected)
        setBase64(select_to_base64)
    };

    const blobToBase64 = (blob) => {
        return new Promise((resolve, _) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(blob);
            fileReader.onloadend = function () {
                resolve(fileReader.result); // Here is the base64 string
            }
        });
    }

    async function uploadFile() {
        if (base64 !== '') {
            setUploading(true)
            let response = await ResourceManager.uploadBase64(base64, name, entity)
            setUploading(false)
            if (response.success === 1) {
                setBase64('')
                var t = response.body
                t.entity = entity
                save(t)
                closeModal()
            }
            else {
                console.warn(response)
                setErrors(response.message)

                setTimeout(function(){
                    setErrors('')
                }, 2000);
            }
        }
        else {
            console.log('select a file...')
        }
    }


    function save(data) {
        update(field, data)
    }

    function closeModal() {
        setShowModal(false)
    }

    function openModal() {
        setShowModal(true)
        setErrors('')
    }

    function removeFile(value, entity) {
        console.warn("removing file: ", value, entity)
        update(field, null)
    }


    return (
        <>
            <Form.Group>
                <Form.Label className='small'>{label}</Form.Label>
                <br />
                <InputGroup>
                    {typeof value !== 'undefined' && value !== null ? (
                        <>
                            {validation.type === 'image' ? (
                                <InputGroup.Text className="w-75 bg-white" style={{ flexFlow: 'wrap' }}>
                                    <span className='me-1 mb-1 mt-1'>
                                        <Image
                                            src={ResourceManager.getFileUrl(value, entity)}
                                            alt="image"
                                            width={'auto'}
                                            height={'50px'}
                                        />
                                        <Badge onClick={() => removeFile(value, entity)} pill bg="danger" role="button" className='align-top' style={{ marginTop: -5, marginLeft: -8 }}>
                                            <FontAwesomeIcon icon="times" />
                                        </Badge>
                                    </span>
                                </InputGroup.Text>
                            ) : (
                                <InputGroup.Text className="w-75">
                                    <Badge bg="info" style={{ maxWidth: '100%', whiteSpace: 'break-spaces' }}>
                                        {value.filename.substr(0, 20)}
                                        <Badge onClick={() => removeFile(value, entity)} pill bg="danger" role="button" className='ms-1'>
                                            <FontAwesomeIcon icon="times" />
                                        </Badge>
                                    </Badge>
                                </InputGroup.Text>
                            )}

                        </>
                    ) : (
                        <Form.Control className="w-75" disabled value={''} />
                    )}
                    <Button className="w-25" variant="secondary" onClick={() => openModal()}>
                        {validation.type === 'image' ? (
                            <FontAwesomeIcon icon="image" />
                        ) : (
                            <FontAwesomeIcon icon="file-import" />
                        )}
                    </Button>
                </InputGroup>

            </Form.Group>

            <Modal show={showModal} onHide={closeModal} size="xs" scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{LanguageProvider.get('defaults.select_file')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {uploading === false ? (
                        <input type="file" onChange={onChange} />
                    ) : (
                        <p className='lead'><FontAwesomeIcon icon="spinner" spin /></p>
                    )}

                    {errors.length > 0 && (
                        <Alert variant='danger' className='mt-4'>
                            {errors}
                        </Alert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={uploadFile}>
                        {LanguageProvider.get('defaults.save')}
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        {LanguageProvider.get('defaults.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};