import React, { useState } from 'react';
import { Form, InputGroup, Button, Modal, Alert, Row, Col, Card, Spinner } from "react-bootstrap";
import { LanguageProvider } from '../../../core/language-provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import './style.css';
import { settings } from '../../../config/settings';

const placesLibrary = ['places']

export default function AddressInput({ entity, update, value, fieldKey, view }) {

    const [formattedAddress, setFormattedAddress] = useState('');
    const [addressComponents, setAddressComponents] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const [errors, setErrors] = useState('');


    function closeModal() {
        setShowModal(false)
        setErrors('')
        setAddressComponents([])
        setFormattedAddress('')
    }

    function openModal() {
        setShowModal(true)
    }

    function save() {
        var inputAddress = document.querySelector('#' + entity + '_address_input_value').value
        update(fieldKey, inputAddress)
        //update(fieldKey, formattedAddress)


        var options = view.properties.columns[fieldKey].options;
        console.warn(options, addressComponents)
        if(options.autocompile) {
            if(options.autocompile.country) {
                update(options.autocompile.locality, addressComponents.country)
            }
            if(options.autocompile.latitude) {
                update(options.autocompile.latitude, lat)
            }
            if(options.autocompile.locality) {
                update(options.autocompile.locality, addressComponents.locality)
            }
            if(options.autocompile.longitude) {
                update(options.autocompile.longitude, lng)
            }
            if(options.autocompile.postalCode) {
                update(options.autocompile.postalCode, addressComponents.postalCode)
            }
            if(options.autocompile.route) {
                update(options.autocompile.route, addressComponents.route)
            }
            if(options.autocompile.streetNumber) {
                update(options.autocompile.streetNumber, addressComponents.streetNumber)
            }
        }

        closeModal()
    }


    const [searchResult, setSearchResult] = useState('');
    const [lat, setLat] = useState(45.0);
    const [lng, setLng] = useState(10.5);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: settings.googleMapKey ? settings.googleMapKey : 'insert_google_map_key_in_settings',
        libraries: placesLibrary
    });
    const onLoad = (autocomplete) => {
        setSearchResult(autocomplete);
    }
    function onPlaceChanged() {
        if (searchResult != null) {
            const place = searchResult.getPlace();
            console.warn("place", place)

            var address_components = place.address_components;
            var itemRoute='';
            var itemLocality='';
            var itemCountry='';
            var itemPostalCode='';
            var itemStreetNumber='';
            var itemLatitude=place.geometry.location.lat();
            var itemLongitude=place.geometry.location.lng();

            for(var j=0; j<address_components.length; j++) {
                var arrAddress = address_components[j]
                if (arrAddress.types[0] == "route"){
                    itemRoute = arrAddress.long_name;
                }
                if (arrAddress.types[0] == "locality"){
                    itemLocality = arrAddress.long_name;
                }
                if (arrAddress.types[0] == "country"){
                    itemCountry = arrAddress.long_name;
                }
                if (arrAddress.types[0] == "postal_code"){
                    itemPostalCode = arrAddress.long_name;
                }
                if (arrAddress.types[0] == "street_number"){
                    itemStreetNumber = arrAddress.long_name;
                }
            }

            setLat(itemLatitude)
            setLng(itemLongitude)

            setFormattedAddress(place.formatted_address)
            setAddressComponents({
                latitude: itemLatitude,
                longitude: itemLongitude,
                route: itemRoute,
                locality: itemLocality,
                country: itemCountry,
                postalCode: itemPostalCode,
                streetNumber: itemStreetNumber
            })
        }
        else {
            setErrors("Please enter text");
        }
    }




    return (
        <>
            <InputGroup>
                {typeof value !== 'undefined' && value ? (
                    <Form.Control as="textarea" size="sm" rows={3} value={value ? value : ''} className="w-75" disabled />
                ) : (
                    <Form.Control className="w-75" disabled />
                )}
                <Button className="w-25" variant="secondary" onClick={() => openModal()}>
                    <FontAwesomeIcon icon="location-dot" />
                </Button>
            </InputGroup>


            <Modal show={showModal} onHide={closeModal} size="lg" scrollable={true} backdrop="static" backdropClassName='backdropped'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {LanguageProvider.get('defaults.address')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='mt-3'>
                        <Col lg={6}>
                            {value ? (
                                <>
                                    <p className='text-muted'>{value}</p>
                                </>
                            ) : (
                                <></>
                            )}

                            {errors.length > 0 && (
                                <Alert variant='danger' className='mb-4'>
                                    {errors}
                                </Alert>
                            )}

                            {!isLoaded ? (
                                <Spinner />
                            ) : (
                                <Autocomplete
                                    onPlaceChanged={onPlaceChanged}
                                    onLoad={onLoad}
                                    options={{
                                        types: [] // ["address"] empty for all types
                                    }}
                                >
                                    <Form.Control id={entity + '_address_input_value'} type="text" placeholder={LanguageProvider.get('defaults.insert_address')}></Form.Control>
                                    
                                </Autocomplete>
                            )}

                        </Col>
                        <Col lg={6}>
                            {(addressComponents) && (
                                <Card>
                                    <Card.Body>
                                        <GoogleMap
                                            mapContainerStyle={{height:300}}
                                            mapContainerClassName="map-container"
                                            center={{ lat: lat, lng: lng }}
                                            zoom={17.5}
                                        >
                                            <Marker 
                                                position={{ lat: lat, lng: lng }} 
                                            />
                                        </GoogleMap>
                                    </Card.Body>
                                </Card>
                            )}
                        </Col>
                    </Row>

                    <br />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={save}>
                        {LanguageProvider.get('defaults.save')}
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        {LanguageProvider.get('defaults.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};