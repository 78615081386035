import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { countryPhonePrefixes } from './prefix';
import './style.css';
import './flags.css';


export default function PhoneInput({ entity, update, value, fieldKey }) {

    const [country, setCountry] = useState('IT');
    const [prefix, setPrefix] = useState('39');


    useEffect(() => {
        if(value.length) {
            detectCountryCode(value)
        }
        else {
            update(fieldKey, prefix)
        }
    }, [value]);


    function changeCountry(item) {
        setCountry(item.iso)
        setPrefix(item.code)
        update(fieldKey, item.code)
    }


    function detectCountryCode(num) {
        var i = 1;
        var country_code;
        
        while (!country_code || i <= num.length) {
            country_code = num.slice(0, i);
            for(var j=0; j<countryPhonePrefixes.length; j++) {
                if(countryPhonePrefixes[j].code === country_code) {
                    //console.warn(countryPhonePrefixes[j]) //country of the number
                    setCountry(countryPhonePrefixes[j].iso)
                    setPrefix(countryPhonePrefixes[j].code)
                }
            }
            i++;
        }
    }
   




    return (
        <>
            <InputGroup className="mb-3">
                <Dropdown as={ButtonGroup}>
                    <Button variant="outline-secondary" size='sm'>
                        <span style={{width: 16, display: 'inline-block'}} className={'me-1 flag flag-' + country.toLowerCase()}></span>
                    </Button>

                    <Dropdown.Toggle split variant="outline-secondary" id="dropdown-split-basic" />

                    <Dropdown.Menu id="select-phone-input">
                        {countryPhonePrefixes.map((item, i) => {
                            var countryCode = item.iso.toLowerCase()
                            return (
                                <Dropdown.Item className='small' key={i} onClick={() => changeCountry(item)}>
                                    <span style={{width: 16, display: 'inline-block'}} className={'me-1 flag flag-' + countryCode}></span> {item.country}
                                </Dropdown.Item>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                <Form.Control
                    type="text"
                    value={value}
                    onChange={(event) => update(fieldKey, event.target.value)}
                />
            </InputGroup>
        </>
    );
};