import { entity_language } from '../../config/entity_language';

export const language = {
    defaults: {
        no_results: 'Nessun risultato trovato.',
        not_found: 'Pagina non trovata.',
        rows: 'Righe',
        columns: 'Colonne',
        view: 'Visualizza',
        edit: 'Modifica',
        delete: 'Elimina',
        clear: 'Resetta',
        image: 'Immagine',
        delete_title: 'Elimina elemento',
        delete_msg: 'Confermi di voler rimuovere questo elemento?',
        details: 'Dettagli',
        close: 'Chiudi',
        open: 'Apri',
        error: 'Errore',
        save: 'Salva',
        back: 'Torna indietro',
        search: 'Cerca',
        create: 'Crea nuovo',
        results_per_page: 'Risultati per pagina',
        no_image: 'Nessuna immagine.',
        loading: 'Caricamento',
        true: 'Sì',
        false: 'No',
        select: 'Seleziona elemento',
        select_file: 'Seleziona file',
        add: 'Aggiungi',
        options: 'Opzioni',
        import: 'Importa',
        export: 'Esporta',
        paste_here: 'Incolla qui',
        check: 'Controlla',
        reset: 'Reset',
        preview: 'Anteprima',
        download: 'Scarica',
        open_link: 'Apri link',
        open_file: 'Apri file',
        elements: 'Elementi',
        refresh: 'Ricarica',
        clone: 'Duplica',
        selected_rows: 'Selezionati',
        delete_selection: 'Elimina selezione',
        delete_selection_confirm: 'Confermi di voler eliminare le righe selezionate?',
        confirm: 'Conferma',
        go_back_title: 'Sei sicuro di voler uscire?',
        go_back_text: 'Se esci da questa schermata senza salvare perderai tutte le modifiche apportate.',
        exit: 'Esci comunque',
        address: 'Indirizzo',
        insert_address: 'Digita un indirizzo'
    },
    search: {
        filters: 'Filtri di ricerca',
        search_fields: 'Campi di ricerca',
        search_fields_subtitle: 'Seleziona o deseleziona i campi su cui effettuare la ricerca.',
        add_filter: 'Aggiungi filtro',
        add_filter_subtitle: 'Aggiungi filtri standard su campi specifici.',
        active_filters: 'Filtri attivi',
        active_filters_subtitle: 'Gestisci i filtri attualmente attivi sulla ricerca.',
        field_label: 'Campo',
        type_label: 'Tipo filtro',
        field_value: 'Valore',
        select_constraint_field: 'Seleziona prima il campo del filtro.'
    },
    errors: {
        insert: 'Si è verificato un errore. Controlla i campi e riprova.',
        update: 'Si è verificato un errore. Controlla i campi e riprova.',
        get: 'Si è verificato un errore.'
    },
    header: {
        home: 'Homepage',
        profile: 'Profilo',
        settings: 'Impostazioni',
        notifcations: 'Notifiche',
        search: 'Ricerca',
        about: 'Informazioni',
        logout: 'Logout'
    },
    login: {
        title: 'Effettua il login',
        subtitle: '',
        username: 'Utente',
        password: 'Password',
        login: 'Accedi',
        forgot: 'Hai dimenticato la password?',
        remember: 'Ricordami',
        send_forgot_password_key: 'Invia pin',
        reset_password: 'Resetta la tua password',
        insert_password_key: 'Inserisci il pin che hai ricevuto via e-mail e la nuova password. Se non hai ricevuto il pin controlla anche nella posta indesiderata.',
        password_key: 'Pin ricevuto',
        new_password: 'Nuova password',
        confirm_new_password: 'Conferma password',
        confirm_reset: 'Conferma',
        back_to_login: 'Torna al login',
        change_password: 'Devi modificare la password',
        change_password_desc: 'Riceverai un pin via e-mail da inserire nel prossimo step per modificare la tua password.'
    },
    logout: {
        title: 'Stai per eseguire il logout.',
        subtitle: 'Sei sicuro di voler effettuare il logout?',
        confirm: 'Confermo'
    },
    settings: {
        user_settings: 'Impostazioni utente',
        general: 'Generali',
        avatar: 'Immagine profilo',
        security: 'Sicurezza',
        name: 'Nome',
        surname: 'Cognome',
        sex: 'Sesso',
        general_success: 'Profilo modificato con successo.',
        general_save: 'Modifica profilo',
        female: 'Donna',
        male: 'Uomo',
        password: 'Password attuale',
        new_password: 'Nuova password',
        confirm_password: 'Conferma nuova password',
        security_save: 'Modifica password',
        security_success: 'Password modificata con successo.',
        passwords_match_error: 'Le password non coincidono.',
        avatar_save: 'Aggiorna avatar',
        avatar_now: 'Immagine profilo attuale',
        avatar_success: 'Immagine profilo modificata con successo.'
    },
    ...entity_language
};