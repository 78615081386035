import { ApiManager } from '../../core/api-manager';
import { DataManager } from '../../core/data-manager';
import { settings } from '../../config/settings';
import { LanguageProvider } from '../../core/language-provider';
import { toast } from 'react-toastify';
import { Gcal } from '../pages/GoogleCalendar/js/gcal';




export async function onInsert(response, entity, data) {

    if(entity === "task") {
        if(response.success === 1) {
            var loadCal = localStorage.getItem("GoogleCalendarDefaultCal") ? JSON.parse(localStorage.getItem("GoogleCalendarDefaultCal")) : null
            if(loadCal) {
                var token = localStorage.getItem("GoogleAuthToken");
                var event = {
                    summary: "evento di prova",
                    start: {
                        dateTime: new Date().toISOString(),
                        timeZone: "Europe/Paris",
                    },
                    end: {
                        dateTime: new Date(new Date().getTime() + 3600000).toISOString(),
                        timeZone: "Europe/Paris",
                    },
                }
        
                var res = await Gcal.createEvent(token, event, loadCal.id)
                console.warn('create event', res)
            }
        }
    }
}