import React, { useState, useEffect } from 'react';
import { Container, Spinner, Button, Form } from "react-bootstrap";
import { LanguageProvider } from '../../../core/language-provider';
import { ViewManager } from '../../../core/view-manager';
import { Utils } from '../../../core/utils';
import Field from '../../../components/Entity/Field';
import InstanceCompilerTopHook from '../../../config/hooks/InstanceCompilerTopHook';


export default function FormEntityInstanceCompiler({ entity, confirmCallback, initData={}, excludedFields=[] }) {
    
    const [data, setData] = useState({})
    const [scopeData, setScopeData] = useState({})
    const [validated, setValidated] = useState(false);
    

    useEffect(() => {
        init()
    }, [entity]);


    function initializeKeyValue(properties, key) {
        var type = properties.columns[key].input_type;
        if(type === 'externalEntity') {
            setDataKeyValue(key, '')
        }
        else if(type === 'multipleExternalEntity') {
            setDataKeyValue(key, [])
        }
        else if(type === 'deepExternalEntity') {
            setDataKeyValue(key, [])
        }
        else if(type === 'boolean') {
            if(properties.columns[key].options.default) {
                setDataKeyValue(key, properties.columns[key].options.default)
            }
            else {
                setDataKeyValue(key, false)
            }
        }
        else if(type === 'option') {
            if(properties.columns[key].options.default) {
                var v = {
                    value: properties.columns[key].options.default,
                    label: properties.columns[key].options.default
                }
                setDataKeyValue(key, v)
            }
            else {
                setDataKeyValue(key, '')
            }
        }
        else if(type === 'file') {
            setDataKeyValue(key, null)
        }
        else {
            if(properties.columns[key].options.default) {
                setDataKeyValue(key, properties.columns[key].options.default)
            }
            else {
                setDataKeyValue(key, '')
            }
            
        }
    }

    async function init(){
        //init properties
        const properties = ViewManager.getEntityProperties(entity);
        const availableFields = Utils.cloneObject(ViewManager.getEditableColumns(entity));

        updateScopeData({'availableFields':availableFields, 'properties':properties});

        if(Object.keys(initData).length === 0) {
            setData({})
            availableFields.map((key, i) => {
                if(excludedFields.includes(key) === false) {
                    initializeKeyValue(properties, key)
                }
            })
        }
        else {
            setData({})
            availableFields.map((key, i) => {
                if(excludedFields.includes(key) === false) {
                    if(typeof(initData[key]) !== 'undefined' && initData[key] !== null) {
                        var type = properties.columns[key].input_type;
                        if(type === 'deepExternalEntity') {
                            if(initData[key] === null) {
                                setDataKeyValue(key, [])
                            }
                            else {
                                setDataKeyValue(key, initData[key])
                            }
                        }
                        else {
                            setDataKeyValue(key, initData[key])
                        }
                    }
                    else {
                        initializeKeyValue(properties, key)
                    }
                }
            })
            //setData(initData)
        }
    }

    function setDataKeyValue(key, value) {
        setData((prevState) => ({
            ...prevState,
            [key]: value
        }));
    }

    function updateScopeData(obj) {
        let updatedData = scopeData;
        Object.assign(updatedData, obj)
        setScopeData(updatedData)
    }

    function handleSave() {
        const form = document.getElementById('form-compiler-' + entity)
        setValidated(true);
        
        if (form.checkValidity() === false) {
            console.log('form is not valid')
        }
        else {
            console.log(data)
            console.log('form is valid')
            confirmCallback(data)
        }
    }

    function entityFieldCallback(key, value) {
        setDataKeyValue(key, value)
    }

    if(scopeData && scopeData.properties && data){
        return (
            <>
                <InstanceCompilerTopHook data={data} setDataKeyValue={setDataKeyValue} entity={entity} />

                <Form id={'form-compiler-' + entity} noValidate validated={validated} onSubmit={handleSave}>
                    <div className='row'>
                        {scopeData.availableFields.map((key, i) => {
                            var type = scopeData.properties.columns[key].input_type;
                            if(excludedFields.includes(key) === false) {
                                return (
                                    <Field data={data} key={i} view={scopeData} type={type} value={data[key]} fieldKey={key} entity={entity} save={entityFieldCallback} />
                                )
                            }
                            else {
                                //key in excluded elements...
                            }
                        })}
                    </div>
                </Form>

                <div className='text-start'>
                    <Button size='lg' variant="success" onClick={handleSave}>
                        {LanguageProvider.get('defaults.save')}
                    </Button>
                </div>
            </>
        );
    }
    else {
        return (
            <Container fluid="md" className='mt-5 mb-5'>
                <div className='text-center p-5'>
                    <Spinner animation="border" variant="primary" />
                    <p className='text-primary'>{LanguageProvider.get('defaults.loading')}</p>
                </div>
            </Container>
        )
    }
};