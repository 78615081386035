import React from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';


export default function CodeEditorComponent({ entity, update, value, fieldKey, options }) {

    //https://github.com/wooorm/refractor#syntaxes
    
    return (
        <>
            <CodeEditor
                value={value}
                language={options.language ? options.language : 'js'}
                placeholder="Please enter code."
                onChange={(evn) => update(fieldKey, evn.target.value)}
                padding={15}
                style={{
                    backgroundColor: "#f5f5f5",
                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                    minHeight: 240
                }}
            />
        </>
    );
};