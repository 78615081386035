export const entity_language = {
    entity: {
        user: 'Utenti',
        user_group: 'Gruppi',
        user_entity_saved_filters: 'Filtri salvati',
        user_entity_preferences: 'Preferenze entità',
        log: 'Log di sistema',
        customer: 'Clienti',
        customer_contacts: 'Contatti',
        service: 'Servizi',
        deal: 'Contratti',
        task: 'Task',
        gyv_task: 'Scadenze',
        notes: 'Note'
    },
    labels: {
        "user": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idUserGroup": 'Gruppo',
            "name": 'Nome',
            "surname": 'Cognome',
            "username": 'Username',
            "email": 'Email',
            "level": 'Livello utente',
            "avatar": 'Avatar',
            "password": 'Password'
        },
        "user_group": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome gruppo',
        },
        "user_entity_saved_filters": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Utente',
            "entity": 'Entità',
            "name": 'Nome',
            "notes": 'Note',
            "content": 'Json filtri'
        },
        "user_entity_preferences": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "author": 'Utente',
            "entity": 'Entità',
            "content": 'Json preferenze'
        },
        "log": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idItem": 'Id elemento',
            "idUser": 'Utente',
            "entity": 'Entità',
            "operation": 'Operazione',
            "operation.options.0_DELETE": 'Elimina',
            "operation.options.1_EDIT": 'Modifica',
            "operation.options.2_ADD": 'Aggiungi',
            "operation.options.3_LOGIN": 'Login',
            "operation.options.4_LOGOUT": 'Logout',
            "notes": 'Note',
        },
        "customer": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome cliente',
            "phone": 'Telefono',
            "mobile": 'Cellulare',
            "address": 'Indirizzo',
            "postalCode": 'Codice postale',
            "city": 'Città',
            "province": 'Provincia',
            "region": 'Regione',
            "state": 'Stato',
            "atecoCode": 'Ateco',
            "vatNumber": 'P.iva',
            "sdiCode": 'SDI',
            "pec": 'PEC',
            "email": 'Email',
            "website": 'Sito web',
            "idPaymentCondition": 'Condizioni di pagamento',
            "idVatCondition": 'Condizioni iva',
            "notes": 'Note',
            "contacts": 'Contatti'
        },
        "customer_contacts": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome',
            "surname": 'Cognome',
            "role": 'Ruolo',
            "phone": 'Telefono',
            "email": 'Email',
            "address": 'Indirizzo',
            "notes": 'Note'
        },
        "service": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome',
            "notes": 'Note'
        },
        "deal": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "name": 'Nome',
            "notes": 'Note',
            "idCustomer": 'Cliente',
            "idService": 'Servizio',
            "idUser": 'Utente',
            "year": 'Anno',
            "services": 'Servizi',
            "price": 'Prezzo',
            "file": 'Allegato',
            "dealDate": 'Data contratto',
            "deadline": 'Data fine',
            "status": 'Stato',
            "status.options.0_PENDING": 'In attesa',
            "status.options.10_COMPLETED": 'Completato',
            "author": 'Utente',
            "company": 'Azienda',
            "company.options.GRUPPOYEC": 'Gruppo Yec',
            "company.options.BOHTECH": 'Boh Tech',
        },
        "task": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "idCustomer": 'Cliente',
            "idSubcategory": 'Bando',
            "idService": 'Servizio',
            "idUser": 'Utente',
            "deadline": 'Data scadenza',
            "referent": 'Referente',
            "notes": 'Note',
            "status": 'Stato',
            "status.options.0_IN_PROGRESS": 'In progress',
            "status.options.1_COMPLETED": 'Completato',
            "status.options.2_CANCELED": 'Cancellato',
            "status.options.3_NOT_STARTED": 'Non iniziato'
        },
        "gyv_task": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "customer": 'Cliente',
            "subcategory": 'Bando',
            "service": 'Servizio',
            "idUser": 'Utente',
            "deadline": 'Data scadenza',
            "referent": 'Referente',
            "notes": 'Note',
            "status": 'Stato',
            "status.options.0_IN_PROGRESS": 'In progress',
            "status.options.1_COMPLETED": 'Completato',
            "status.options.2_CANCELED": 'Cancellato',
            "status.options.3_NOT_STARTED": 'Non iniziato'
        },
        "notes": {
            "id": 'id',
            "date": 'Data',
            "lastUpdate": 'Ultimo aggiornamento',
            "deleted": 'Eliminato',
            "title": 'Titolo',
            "notes": 'Note',
            "author": 'Utente',
        },
    }
};