import React, { useState, useEffect } from 'react';
import { Button, Form, Badge, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ViewManager } from '../../../core/view-manager';
import { Utils } from '../../../core/utils';
import ModalPicker from '../../../components/Modals/ModalPicker';


export default function ExternalEntity({ parentEntity, parentItem, externalField, label, field, value, entity, update, required }) {

    const [view, setView] = useState(null)

    useEffect(() => {
        const properties = ViewManager.getEntityProperties(entity);
        const availableFields = Utils.cloneObject(ViewManager.getVisibleColumns(entity));
        setView({'availableFields':availableFields, 'properties':properties})
    }, [entity]);


    /* modal picker */
    const [statusModalPicker, setStatusModalPicker] = useState(false)
    const [modalPickerData, setModalPickerData] = useState(null)
    const [labelPicker, setLabelPicker] = useState(null)
    const [keyPicker, setKeyPicker] = useState(null)
    const modalPickerEntity = entity

    function closeModalPicker() {
        setStatusModalPicker(false)
        setModalPickerData(null)
    }
    function openPickModal(data, label, itemEntity, key) {
        if(data === null) {
            data = ''
        }
        setLabelPicker(label)
        setModalPickerData(data)
        setKeyPicker(key)
        setStatusModalPicker(true)
    }
    function saveModalPicker(key, value) {
        if (value !== null) {
            update(key, value)
        }
        setModalPickerData(null)
    }



    if(view !== null) {
        return (
            <>
                
                <Form.Group className="field__externalEntity">
                    <Form.Label className='small'>{label}</Form.Label>
                    <br />
                    <InputGroup>
                        {typeof value !== 'undefined' && value ? (
                            <InputGroup.Text className="w-75 bg-white overflow-hidden" style={{flexFlow: 'wrap'}} onClick={() => openPickModal(value, externalField, entity, field)}>
                                <Badge bg="secondary" style={{maxWidth: '100%', whiteSpace: 'break-spaces'}}>
                                    {value[externalField]}
                                </Badge>
                            </InputGroup.Text>
                        ) : (
                            <Form.Control 
                                required={required == true ? true : false}
                                className="w-75" 
                                defaultValue=""
                                onClick={() => openPickModal(value, externalField, entity, field)}
                            />
                        )}
                        <Button className="w-25" variant="secondary" onClick={() => openPickModal(value, externalField, entity, field)}>
                            <FontAwesomeIcon icon="bars" />
                        </Button>
                    </InputGroup>
                </Form.Group>


                {statusModalPicker ? (
                    <ModalPicker statusModalPicker={statusModalPicker} parentEntity={parentEntity} parentItem={parentItem} view={view} selected={modalPickerData} entity={modalPickerEntity} keyPicker={keyPicker} labelPicker={labelPicker} saveModalPicker={saveModalPicker} closeModalPicker={closeModalPicker} />
                ) : (
                    <></>
                )}
                
            </>
        )
    }
};