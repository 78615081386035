import React from 'react';
import { LanguageProvider } from '../../../core/language-provider';
import { Editor } from '@tinymce/tinymce-react';
import { ResourceManager } from '../../../core/resource-manager';
import { settings } from '../../../config/settings';


export default function RichText({ entity, update, value, fieldKey }) {


    const custom_image_upload_handler = (blobInfo, progress) => new Promise(async (resolve, reject) => {
        let response = await ResourceManager.uploadImageTiny(blobInfo, blobInfo.filename(), entity);
        console.warn(response)
        if(response.success === 1) {
            var body = response.body
            let location = settings.ckdPath + 'upload/' + entity + '/' + body.hash + '.' + body.extension
            resolve(location);
        }
        else {
            reject('Image upload failed due to a XHR Transport error. Code: ' + response.status);
        }
    });


    return (
        <>
            <Editor
                apiKey={settings.tinyKey}
                onEditorChange={(value, editor) => update(fieldKey, value)}
                value={value}
                init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                        'lists', 'link', 'image', 'preview',
                        'visualblocks', 'code', 'fullscreen',
                        'media', 'table', 'advlist', 'code', 'wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | fullscreen',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif;font-size:14px; }',
                    //images_upload_url: 'demo.php',
                    images_upload_handler: custom_image_upload_handler,
                    image_caption: true
                }}
            />
        </>
    );
};