import React, { useState } from 'react';
import { LanguageProvider } from '../../../core/language-provider';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalEntityConfig from '../../../components/Modals/ModalEntityConfig';
import ModalEntityExport from '../../../components/Modals/ModalEntityExport';
import ModalEntityImport from '../../../components/Modals/ModalEntityImport';


export default function DataGridOptions({ props, data, entity, entityPreferences, updatePreferences, params, searchObj }) {


    const [modalEntityConfigStatus, setModalEntityConfigStatus] = useState(false)
    function handleViewUserSettings() {
        setModalEntityConfigStatus(true)
    }
    function closeModalEntityConfig(obj) {
        updatePreferences(obj) 
        setModalEntityConfigStatus(false)
    }



    const [modalEntityExportStatus, setModalEntityExportStatus] = useState(false)
    function handleExportExcel() {
        setModalEntityExportStatus(true)
    }
    function closeModalEntityExport() {
        //reload data
        setModalEntityExportStatus(false)
    }


    
    const [modalEntityImportStatus, setModalEntityImportStatus] = useState(false)
    function handleImportExcel() {
        setModalEntityImportStatus(true)
    }
    function closeModalEntityImport() {
        //reload data
        setModalEntityImportStatus(false)
    }



    if(data && props[entity] !== null){
        
        return (
            <>
                <div className='text-end mb-2'>
                    <Dropdown>
                        <Dropdown.Toggle size="sm" variant="outline-secondary">
                            {LanguageProvider.get('defaults.options')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item className="small" onClick={() => handleExportExcel()}>{LanguageProvider.get('defaults.export')} Excel <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="file-download" /></span></Dropdown.Item>
                            {/*
                                <Dropdown.Item className="small" onClick={() => handleImportExcel()}>{LanguageProvider.get('defaults.import')} Excel <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="file-upload" /></span></Dropdown.Item>
                            */}
                            <Dropdown.Item className="small" onClick={() => handleViewUserSettings()}>{LanguageProvider.get('header.settings')} <span style={{width:30, textAlign:'center'}} className='ms-3 float-end'><FontAwesomeIcon icon="cog" /></span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <ModalEntityConfig entity={entity} props={props} entityPreferences={entityPreferences[entity]} status={modalEntityConfigStatus} close={closeModalEntityConfig} />
                <ModalEntityExport entity={entity} props={props} entityPreferences={entityPreferences[entity]} status={modalEntityExportStatus} close={closeModalEntityExport} params={params} searchObj={searchObj} />
                <ModalEntityImport entity={entity} props={props} entityPreferences={entityPreferences[entity]} status={modalEntityImportStatus} close={closeModalEntityImport} />
            </>
        );
    }
};