import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function ConnectionStatus() {

    const [offline, setOffline] = useState(false);

    useEffect(() => {
        if(!navigator.onLine) {
            setOffline(true)
        }
    }, []);


    return (
        <>
            {offline && (
                <Container fluid className='text-end small p-3 text-muted'>
                    Network status: <b>Offline</b> <FontAwesomeIcon className='ms-1' icon={'globe'} color={'red'} size={'lg'} fade />
                    <br/>
                    / <span role='button' className='link-primary' onClick={() => window.location.reload(true)}>Refresh</span>
                </Container>
            )}
            {!offline && (
                <></>
            )}
        </>
    );
};