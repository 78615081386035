import React, { useState, useEffect } from 'react';
import { Button, Modal, Spinner, Image, Alert } from "react-bootstrap";
import { LanguageProvider } from '../../../core/language-provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResourceManager } from '../../../core/resource-manager';


export default function ModalViewFile({ data, closeModalViewFile }) {

    const [isLoading, setIsLoading] = useState(true)
    const [statusModalViewFile, setStatusModalViewFile] = useState(false)
    const [fileLink, setFileLink] = useState(null)


    useEffect(() => {
        if (data !== null) {
            setStatusModalViewFile(true)
            console.warn(data)
            var link = ResourceManager.getFileUrl(data, data.entity)
            setFileLink(link)
            setIsLoading(false)
        }
    }, [data]);


    async function downloadFile(rD) {
        var url = ResourceManager.getFileUrl(rD, rD.entity)
        window.open(url, '_blank');
    }


    function close() {
        closeModalViewFile()
        setIsLoading(true)
    }



    if (data !== null) {
        return (
            <>
                <Modal show={statusModalViewFile} onHide={close} size="xl" fullscreen={true} scrollable={true} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title className='small'>
                            {LanguageProvider.get('defaults.preview')} {(data.filename) ? (<>{data.filename}</>) : (<></>)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {(!isLoading && fileLink !== null) ? (
                            <>
                                {(() => {
                                    switch (data.extension.toUpperCase()) {
                                        case 'JPG': return (
                                            <Image
                                                src={fileLink}
                                                alt="image"
                                                width={'100%'}
                                                height={'auto'}
                                            />
                                        );
                                        case 'JPEG': return (
                                            <Image
                                                src={fileLink}
                                                alt="image"
                                                width={'100%'}
                                                height={'auto'}
                                            />
                                        );
                                        case 'PNG': return (
                                            <Image
                                                src={fileLink}
                                                alt="image"
                                                width={'100%'}
                                                height={'auto'}
                                            />
                                        );
                                        case 'PDF': return (
                                            <iframe src={fileLink}
                                                display="block"
                                                position="relative"
                                                height="100%"
                                                width="100%"
                                            />
                                        );
                                        case 'DOCX': {
                                            var link = 'https://view.officeapps.live.com/op/view.aspx?src=' + fileLink
                                            return (
                                                <>
                                                    <iframe src={link}
                                                        display="block"
                                                        position="relative"
                                                        height="100%"
                                                        width="100%"
                                                    />
                                                </>
                                            )
                                        };
                                        case 'DOC': {
                                            var link = 'https://view.officeapps.live.com/op/view.aspx?src=' + fileLink
                                            return (
                                                <>
                                                    <iframe src={link}
                                                        display="block"
                                                        position="relative"
                                                        height="100%"
                                                        width="100%"
                                                    />
                                                </>
                                            )
                                        };
                                        default: return (
                                            <div style={{ display: 'flex', minHeight: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                                                <Alert variant='primary'>
                                                    Preview non disponibile. Scarica il file.
                                                </Alert>
                                            </div>
                                        );
                                    }
                                })()}
                            </>
                        ) : (
                            <Spinner animation="border" variant="primary" />
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => downloadFile(data)} variant="outline-primary">
                            <FontAwesomeIcon icon="download" className='me-2' /> {LanguageProvider.get('defaults.download')}
                        </Button>
                        <Button variant="secondary" onClick={close}>
                            {LanguageProvider.get('defaults.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
};