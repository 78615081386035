import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function FieldTooltip({ fieldKey, view }) {


    if((view.properties.columns[fieldKey].options && view.properties.columns[fieldKey].options.tooltip)) {
        return (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip>
                        {view.properties.columns[fieldKey].options.tooltip}
                    </Tooltip>
                }
            >
                <FontAwesomeIcon icon="circle-info" size="lg" color={'#999'} className='ms-1' />
            </OverlayTrigger>
        )
    }
    else {
        return (
            <></>
        )
    }
};