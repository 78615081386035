import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, InputGroup, Modal, Image, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../../core/language-provider';
import { ResourceManager } from '../../../core/resource-manager';


export default function Signature({ field, label, options, validation, value, update, entity }) {

    const [showModal, setShowModal] = useState(false)
    const [uploading, setUploading] = useState(false);
    const [base64, setBase64] = useState('');

    const [isDrawing, setIsDrawing] = useState(false);
    const canvasRef = useRef(null);
    const contextRef = useRef(null);


    const startDrawing = ({ nativeEvent }) => {
        const { offsetX, offsetY } = nativeEvent;
        setIsDrawing(true);
        contextRef.current.beginPath();
        contextRef.current.moveTo(offsetX, offsetY);
    };

    const finishDrawing = () => {
        setIsDrawing(false);
    };

    const draw = ({ nativeEvent }) => {
        if (!isDrawing) return;
        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
    };

    const clearCanvas = () => {
        contextRef.current.clearRect(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
        );
    };

    const saveSignature = () => {
        const imageDataURL = canvasRef.current.toDataURL();
        // Do something with the imageDataURL (e.g., save it, send it to the server, etc.)
        console.log(imageDataURL);
        setBase64(imageDataURL)
    };

    // Initialize the canvas context
    useEffect(() => {
        if(showModal) {
            const canvas = canvasRef.current;
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;
            const context = canvas.getContext("2d");
            context.scale(1, 1);
            context.lineCap = "round";
            context.lineWidth = 2;
            context.fillStyle = "lightgray";
            context.fillRect(0, 0, canvas.width, canvas.height);
            contextRef.current = context;
        }
    }, [showModal]);


    useEffect(() => {
        if(base64 && base64.length > 10) {
            //upload file
            uploadFile()
        }
    }, [base64]);

    
    async function uploadFile() {
        setUploading(true)        
        let response = await ResourceManager.uploadBase64(base64, 'signature.png', entity)
        setUploading(false)
        if(response.success === 1) {
            setBase64('')
            var t = response.body
            t.entity = entity
            save(t)
            closeModal()
        }
        else {
            console.warn(response)
        }
    }

    function save(data) {
        update(field, data)
    }



    function closeModal() {
        clearCanvas()
        setShowModal(false)
    }

    function openModal() {
        setShowModal(true)
    }

    function removeFile(value, entity) {
        console.warn("removing file: ", value, entity)
        update(field, null)
    }


    return (
        <>
            <Form.Group>
                <Form.Label className='small'>{label}</Form.Label>
                <br />
                <InputGroup>
                    {typeof value !== 'undefined' && value ? (
                        <InputGroup.Text className="w-75 bg-white" style={{ flexFlow: 'wrap' }}>
                            <span className='me-1 mb-1 mt-1'>
                                <Image
                                    src={ResourceManager.getFileUrl(value, entity)}
                                    alt="image"
                                    width={'auto'}
                                    height={'50px'}
                                />
                                <Badge onClick={() => removeFile(value, entity)} pill bg="danger" role="button" className='align-top' style={{ marginTop: -5, marginLeft: -20 }}>
                                    <FontAwesomeIcon icon="times" />
                                </Badge>
                            </span>
                        </InputGroup.Text>
                    ) : (
                        <Form.Control className="w-75" disabled />
                    )}
                    <Button className="w-25" variant="secondary" onClick={() => openModal()}>
                        <FontAwesomeIcon icon="signature" />
                    </Button>
                </InputGroup>
            </Form.Group>


            <Modal show={showModal} onHide={closeModal} size="lg" scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Firma</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <canvas
                        ref={canvasRef}
                        onMouseDown={startDrawing}
                        onMouseUp={finishDrawing}
                        onMouseMove={draw}
                        style={{ border: "1px solid black" }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={clearCanvas}>
                        {LanguageProvider.get('defaults.clear')}
                    </Button>
                    <Button variant="primary" onClick={saveSignature}>
                        {LanguageProvider.get('defaults.save')}
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        {LanguageProvider.get('defaults.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};