import React, { useState, useEffect } from 'react';
import { Button, Modal, Table } from "react-bootstrap";
import { LanguageProvider } from '../../../core/language-provider';
import { DataManager } from '../../../core/data-manager';


export default function ModalDelete({ entity, props, data, closeModalDelete, confirmModalDelete }) {

    const [statusModalDelete, setStatusModalDelete] = useState(false)

    useEffect(() => {
        if(data === null) {
          setStatusModalDelete(false)
        } 
        else {
          setStatusModalDelete(true)
        }
      }, [data]);


    async function confirmModalDeleteFun() {
        if(props.reference_entity) {
            let response = await DataManager.deleteItem(props.reference_entity, data.id)
            if(response.success === 1) {
                confirmModalDelete(data)
            }
        }
        else {
            let response = await DataManager.deleteItem(entity, data.id)
            if(response.success === 1) {
                confirmModalDelete(data)
            }
        }
    }

    if(data !== null) { 
        return (
            <>
                <Modal show={statusModalDelete} onHide={closeModalDelete} size="md" backdrop="static" >
                    <Modal.Header closeButton>
                        <Modal.Title>{LanguageProvider.get('defaults.delete_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{LanguageProvider.get('defaults.delete_msg')}</p>
                        <pre>
                            {data[props.default_key]} <i>id {data.id}</i>
                        </pre>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={confirmModalDeleteFun}>
                            {LanguageProvider.get('defaults.delete')}
                        </Button>
                        <Button variant="secondary" onClick={closeModalDelete}>
                            {LanguageProvider.get('defaults.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
};