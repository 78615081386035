import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Form, FloatingLabel, Button, Alert, Image } from 'react-bootstrap';
import { LanguageProvider } from '../../core/language-provider';
import { LoginManager } from '../../core/login-manager';
import loginBg from '../../config/login.jpg';
import { settings } from '../../config/settings';


export default function Login() {

    const navigate = useNavigate();
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState('');


    async function handleSubmit(e) {
        e.preventDefault();

        var response = await LoginManager.login(username, password, 1);
        console.log(response)
        if(response.success === 0) {
            setErrors(response.message)
        }
    }

    return (
        <Container fluid id="login-container" style={{"backgroundImage": `url('${loginBg}')`}}>
            <div className="form-signin">
                <Form onSubmit={handleSubmit}>
                    <div className="mb-3 text-center">
                        <div id='login-logo'>
                            <Image
                                src={settings.logo}
                                width="150"
                                height="auto"
                                className="mb-3"
                            />
                        </div>
                        <h4>{LanguageProvider.get('login.title')}</h4>
                        <p>{LanguageProvider.get('login.subtitle')}</p>
                    </div>

                    <FloatingLabel label={LanguageProvider.get('login.username')}>
                        <Form.Control type="text" placeholder="name@example.com" onChange={e => setUserName(e.target.value)} />
                    </FloatingLabel>

                    <FloatingLabel label={LanguageProvider.get('login.password')} className="mb-3">
                        <Form.Control type="password" placeholder="password" onChange={e => setPassword(e.target.value)} />
                    </FloatingLabel>
                
                    <div className="mb-3 text-center">
                        <Button className="btn-block w-100" variant="primary" size="lg" type="submit">{LanguageProvider.get('login.login')}</Button>
                    </div>

                    {errors.length > 0 &&
                        <Alert variant='danger'>
                            {errors}
                        </Alert>
                    }
                </Form>
                <div className="text-center">
                    <Link to="/recover">{LanguageProvider.get('login.forgot')}</Link>
                </div>
            </div>
        </Container>
    );
};