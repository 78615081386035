import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Breadcrumb, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../core/language-provider';
import logoYec from '../assets/yec.svg';


export default function About() {
    return (
        <Container fluid='md' className='mt-5 mb-5'>
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}><FontAwesomeIcon icon="home" /></Breadcrumb.Item>
                    <Breadcrumb.Item active>{LanguageProvider.get('header.about')}</Breadcrumb.Item>
                </Breadcrumb>
            </Container>

            <Container fluid className='text-center mt-5 mb-5'>
                <Image src={logoYec} width='180' className='mb-5' />
                <p className='lead text-muted'>GRUPPO YEC è una Tech Company specializzata nello sviluppo di soluzioni digitali innovative e nella promozione online di top brand.</p>
                <p className='lead'><a target="_blank" href="https://gruppoyec.com">https://gruppoyec.com</a></p>
                <a target="_blank" href="https://gruppoyec.com"><Button variant="primary" className='m-1'>Visita il nostro sito</Button></a>
                <a target="_blank" href="https://gruppoyec.com/contact"><Button variant="success" className='m-1'>Contattaci</Button></a>
            </Container>

            <hr/>

            <Container fluid className='text-center mt-5 mb-5 text-muted'>
                <p>Questo framework è sviluppato da GRUPPO YEC SBRL.<br/>Version 3.0.170724 - Build 17/07/2024</p>
            </Container>

        </Container>
    );
};