import { ApiManager } from '../../core/api-manager';
import { DataManager } from '../../core/data-manager';
import { settings } from '../../config/settings';
import { LanguageProvider } from '../../core/language-provider';
import { toast } from 'react-toastify';



export async function preDelete(entity, id) {


    
    return {
        success: 1,
        message: ''
    }
}