import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button } from 'react-bootstrap';
import { DataManager } from '../../core/data-manager';
import { ApiManager } from '../../core/api-manager';
import { LanguageProvider } from '../../core/language-provider';



export default function InstanceCompilerTopHook({ data, entity = null}) {

    useEffect(() => {
        
    }, []);



    if (entity !== null) {
        return (
            <>
                {(() => {
                    switch(entity) {
                        case 'sample':
                            return (
                                <span>Test</span>
                            );
                        break;
                        default:
                            return (
                                <></>
                            );
                        break;
                    }
                })()}
            </>
        )
    }
    else {
        return (
            <></>
        )
    }
};