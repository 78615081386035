import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Alert, Table, Dropdown, Form, Spinner, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageProvider } from '../../../core/language-provider';
import { AuthorizationManager } from '../../../core/authorization-manager';
import { ViewManager } from '../../../core/view-manager';
import { DataManager } from '../../../core/data-manager';
import ModalDetails from '../../../components/Modals/ModalDetails';
import ModalDelete from '../../../components/Modals/ModalDelete';
import FieldPreview from '../../../components/Entity/FieldPreview';
import DataGridOptions from '../../../components/Entity/DataGridOptions';
import DataGridSelection from '../../../components/Entity/DataGridSelection';
import { Utils } from '../../../core/utils';
import DataGridActionHook from '../../../components/Hooks/DataGridAction';
import DataGridTrHook from '../../../config/hooks/DataGridTrHook';
import DataGridLastTdHook from '../../../config/hooks/DataGridLastTdHook';
import DataGridTopHook from '../../../config/hooks/DataGridTopHook';
import _ from "lodash";


export default function DataGrid({ data, setData, props, entity, params, changeSorting, searchObj, setSearchObj, refreshData, loading, total }) {

    const navigate = useNavigate()
    const [userPreferences, setUserPreferences] = useState(null)
    const [selectedRows, setSelectedRows] = useState([])

    useEffect(() => {
        getUserPreferences()
    }, [entity]);

    async function getUserPreferences() {
        var c = [
            {
                fieldName: 'entity',
                type: 'value',
                value: entity
            }
        ]
        var response = await DataManager.searchItems('user_entity_preferences', 1, 1, { 'field': 'id', 'order': 'ASC' }, '', [], c)
        if (response.success === 1) {
            if (response.body && response.body.length) {
                var item = response.body[0]
                var newArr = []
                newArr[entity] = JSON.parse(item.content)
                setUserPreferences(newArr)
            }
            else {
                let c = Utils.cloneObject(ViewManager.getVisibleColumns(entity))
                var defaultPreferences = {
                    'columns': c
                }
                var newArr = []
                newArr[entity] = defaultPreferences
                setUserPreferences(newArr)
            }
        }
    }

    function updatePreferences(obj) {
        var newArr = []
        newArr[entity] = obj
        setUserPreferences(newArr)
    }


    //modals content
    const [modalDetailsData, setModalDetailsData] = useState(null)
    const [modalDeleteData, setModalDeleteData] = useState(null)

    /* panels */
    function edit(i) {
        if (props.reference_entity) {
            navigate('/entity/' + props.reference_entity + '/edit?id=' + data[i].id)
        }
        else {
            navigate('/entity/' + entity + '/edit?id=' + data[i].id)
        }
    }


    /* modals */
    /* open */
    function open(i) {
        let item = data[i]
        setModalDetailsData(item)
    }
    function closeModalDetails() {
        setModalDetailsData(null)
    }
    /* remove */
    function remove(i) {
        let item = data[i]
        setModalDeleteData(item)
    }
    function closeModalDelete() {
        setModalDeleteData(null)
    }
    async function confirmModalDelete(item) {
        var temp = [...data]
        const index = temp.indexOf(item);
        if (index > -1) {
            temp.splice(index, 1);
        }
        setData(temp)
        setModalDeleteData(null)
    }
    function doubleClicked(i) {
        open(i)
    }

    function clone(i) {
        if (props.reference_entity) {
            navigate('/entity/' + props.reference_entity + '/clone?id=' + data[i].id)
        }
        else {
            navigate('/entity/' + entity + '/clone?id=' + data[i].id)
        }
    }

    function openInNewPage(i) {
        navigate('/entity/' + entity + '/item?id=' + data[i].id)
    }




    function orderBy(field) {
        var excluded = ['richText', 'file', 'externalEntity', 'multipleExternalEntity', 'deepExternalEntity']
        var type = props.columns[field].input_type
        if (!excluded.includes(type)) {
            var order = params.sortingOrder === 'desc' ? 'asc' : 'desc';
            changeSorting(field, order)
        }
    }


    function handleCheckboxChange(event, item) {
        const target = event.target
        const checked = target.checked

        var t = [...selectedRows]
        if (checked === true) {
            t.push(item.id)
        }
        else {
            t = Utils.removeFromArray(t, item.id)
        }
        setSelectedRows(t)
        console.log(t)
    }

    function search(obj) {
        if (_.isEqual(obj, searchObj)) {
            console.log('searchObj = : non triggero la ricerca')
            // oggetti uguali non triggero la ricerca
        }
        else {
            console.log('searchObj <> : triggero la ricerca')
            setSearchObj(obj)
        }
    }


    if (data && userPreferences !== null && typeof (userPreferences[entity]) !== 'undefined') {
        return (
            <>

                {/* hook */}
                <DataGridTopHook entity={entity} params={params} data={data} setData={setData} searchObj={searchObj} setSearchObj={search} total={total} />

                <Card>
                    <Card.Body>
                        {data && data.length > 0 ? (
                            <>
                                <Row className='mb-3'>
                                    <Col xs={12} md={4}>
                                        <DataGridSelection data={data} setData={setData} props={props} entity={entity} selectedRows={selectedRows} setSelectedRows={setSelectedRows} refreshData={refreshData} />
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <div className='small text-muted text-center'>
                                            {loading === true ? (
                                                <span>
                                                    {LanguageProvider.get('defaults.loading')} <Spinner className='ms-1' size='sm' animation="border" variant="secondary" />
                                                </span>
                                            ) : (
                                                <span>&nbsp;</span>
                                            )}
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <DataGridOptions data={data} props={props} entity={entity} entityPreferences={userPreferences} updatePreferences={updatePreferences} params={params} searchObj={searchObj} />
                                    </Col>
                                </Row>

                                <div className='data-grid-container'>
                                    <Table striped hover responsive className="datagrid-table small">
                                        <thead className='sticky-top top-0 small'>
                                            <tr>
                                                <th className='multi-select'></th>
                                                {userPreferences[entity].columns.map((item, i) => {
                                                    var chevron = params.sortingOrder === 'desc' ? 'chevron-down' : 'chevron-up';
                                                    return (
                                                        <th key={i} onClick={() => orderBy(item)}>
                                                            {LanguageProvider.getLabel(entity, item)}
                                                            {params.sortingField === item ? (
                                                                <>
                                                                    <FontAwesomeIcon icon={chevron} size={'xs'} color='#999999' className='ms-2' />
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </th>
                                                    )
                                                })}
                                                <th className='head-last-td' colSpan={2}>Azioni</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, i) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        <tr onDoubleClick={() => doubleClicked(i)} data-entity={entity} data-id={item.id ? item.id : 'none'}>
                                                            <td className='multi-select'>
                                                                <Form.Check // prettier-ignore
                                                                    type={'checkbox'}
                                                                    label={''}
                                                                    checked={selectedRows.includes(item.id)}
                                                                    onChange={(e) => handleCheckboxChange(e, item)}
                                                                />
                                                            </td>
                                                            {userPreferences[entity].columns.map((key, j) => {
                                                                return (
                                                                    <td key={j}>
                                                                        <FieldPreview item={item} field={key} props={props} entity={entity} />
                                                                    </td>
                                                                )
                                                            })}
                                                            <td className='text-end last-td' colSpan={2}>
                                                                <table className='ms-auto'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <DataGridLastTdHook item={item} props={props} entity={entity} data={data} setData={setData} refreshData={refreshData} />
                                                                            </td>
                                                                            <td>
                                                                                <Dropdown className='position-static'>
                                                                                    <Dropdown.Toggle variant="outline-secondary" data-bs-boundary="viewport" size="sm">
                                                                                        <FontAwesomeIcon icon="cog" />
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item className='small' onClick={() => openInNewPage(i)}>{LanguageProvider.get('defaults.view')} <span style={{ width: 30, textAlign: 'center' }} className='ms-3 float-end'><FontAwesomeIcon icon="eye" /></span></Dropdown.Item>
                                                                                        {
                                                                                            {
                                                                                                'true': <Dropdown.Item className='small' onClick={() => edit(i)}>{LanguageProvider.get('defaults.edit')} <span style={{ width: 30, textAlign: 'center' }} className='ms-3 float-end'><FontAwesomeIcon icon="edit" /></span></Dropdown.Item>,
                                                                                                'false': <></>,
                                                                                                'group':
                                                                                                    <>
                                                                                                        {props.authorization && item[props.authorization.groupField] && (
                                                                                                            <>
                                                                                                                {props.columns[props.authorization.groupField].input_type === 'number' ? (
                                                                                                                    <>
                                                                                                                        {item[props.authorization.groupField] == JSON.parse(localStorage.getItem('userInfo')).idUserGroup && (
                                                                                                                            <Dropdown.Item className='small' onClick={() => edit(i)}>{LanguageProvider.get('defaults.edit')} <span style={{ width: 30, textAlign: 'center' }} className='ms-3 float-end'><FontAwesomeIcon icon="edit" /></span></Dropdown.Item>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        {item[props.authorization.groupField].id == JSON.parse(localStorage.getItem('userInfo')).idUserGroup && (
                                                                                                                            <Dropdown.Item className='small' onClick={() => edit(i)}>{LanguageProvider.get('defaults.edit')} <span style={{ width: 30, textAlign: 'center' }} className='ms-3 float-end'><FontAwesomeIcon icon="edit" /></span></Dropdown.Item>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>,
                                                                                                'user':
                                                                                                    <>
                                                                                                        {props.authorization && item[props.authorization.userField] && (
                                                                                                            <>
                                                                                                                {props.columns[props.authorization.userField].input_type === 'number' ? (
                                                                                                                    <>
                                                                                                                        {item[props.authorization.userField] == JSON.parse(localStorage.getItem('userInfo')).id && (
                                                                                                                            <Dropdown.Item className='small' onClick={() => edit(i)}>{LanguageProvider.get('defaults.edit')} <span style={{ width: 30, textAlign: 'center' }} className='ms-3 float-end'><FontAwesomeIcon icon="edit" /></span></Dropdown.Item>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        {item[props.authorization.userField].id == JSON.parse(localStorage.getItem('userInfo')).id && (
                                                                                                                            <Dropdown.Item className='small' onClick={() => edit(i)}>{LanguageProvider.get('defaults.edit')} <span style={{ width: 30, textAlign: 'center' }} className='ms-3 float-end'><FontAwesomeIcon icon="edit" /></span></Dropdown.Item>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>
                                                                                            }[AuthorizationManager.checkEntityActionAuth(entity, 'edit')]
                                                                                        }

                                                                                        {AuthorizationManager.checkEntityActionAuth(entity, 'create') !== false ? (
                                                                                            <Dropdown.Item className='small' onClick={() => clone(i)}> {LanguageProvider.get('defaults.clone')} <span style={{ width: 30, textAlign: 'center' }} className='ms-3 float-end'><FontAwesomeIcon icon="clone" /></span></Dropdown.Item>
                                                                                        ) : (
                                                                                            <></>
                                                                                        )}

                                                                                        {
                                                                                            {
                                                                                                'true': <Dropdown.Item className='small' onClick={() => remove(i)}>{LanguageProvider.get('defaults.delete')} <span style={{ width: 30, textAlign: 'center' }} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>,
                                                                                                'false': <></>,
                                                                                                'group':
                                                                                                    <>
                                                                                                        {props.authorization && item[props.authorization.groupField] && (
                                                                                                            <>
                                                                                                                {props.columns[props.authorization.groupField].input_type === 'number' ? (
                                                                                                                    <>
                                                                                                                        {item[props.authorization.groupField] == JSON.parse(localStorage.getItem('userInfo')).idUserGroup && (
                                                                                                                            <Dropdown.Item className='small' onClick={() => remove(i)}>{LanguageProvider.get('defaults.delete')} <span style={{ width: 30, textAlign: 'center' }} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        {item[props.authorization.groupField].id == JSON.parse(localStorage.getItem('userInfo')).idUserGroup && (
                                                                                                                            <Dropdown.Item className='small' onClick={() => remove(i)}>{LanguageProvider.get('defaults.delete')} <span style={{ width: 30, textAlign: 'center' }} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>,
                                                                                                'user':
                                                                                                    <>
                                                                                                        {props.authorization && item[props.authorization.userField] && (
                                                                                                            <>
                                                                                                                {props.columns[props.authorization.userField].input_type === 'number' ? (
                                                                                                                    <>
                                                                                                                        {item[props.authorization.userField] == JSON.parse(localStorage.getItem('userInfo')).id && (
                                                                                                                            <Dropdown.Item className='small' onClick={() => remove(i)}>{LanguageProvider.get('defaults.delete')} <span style={{ width: 30, textAlign: 'center' }} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        {item[props.authorization.userField].id == JSON.parse(localStorage.getItem('userInfo')).id && (
                                                                                                                            <Dropdown.Item className='small' onClick={() => remove(i)}>{LanguageProvider.get('defaults.delete')} <span style={{ width: 30, textAlign: 'center' }} className='ms-3 float-end'><FontAwesomeIcon icon="trash" /></span></Dropdown.Item>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>
                                                                                            }[AuthorizationManager.checkEntityActionAuth(entity, 'delete')]
                                                                                        }

                                                                                        <DataGridActionHook entity={entity} data={item} />

                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <DataGridTrHook item={item} props={props} entity={entity} refreshData={refreshData} />
                                                    </React.Fragment>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td className='text-end' colSpan={userPreferences[entity].columns.length + 3}>
                                                    <span className='text-muted small'>{LanguageProvider.get('defaults.rows')} {data.length}</span>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </>
                        ) : (
                            <>
                                <Alert variant="warning">
                                    {LanguageProvider.get('defaults.no_results')}
                                </Alert>
                            </>
                        )}
                    </Card.Body>
                </Card>

                <ModalDetails entity={entity} props={props} data={modalDetailsData} closeModalDetails={closeModalDetails} />
                <ModalDelete entity={entity} props={props} data={modalDeleteData} closeModalDelete={closeModalDelete} confirmModalDelete={confirmModalDelete} />
            </>
        );
    }
};